export function Calculate() {
    this.sum = function(a, b) {
      return parseInt(a) + parseInt(b);
    };
    this.avg = function(a, b) {
      return (parseInt(a) + parseInt(b)) / 2;
    };
  }
  export function Capitalize([firstChar, ...rest]) {
    return `${firstChar.toUpperCase()}${rest.join("")}`;
  }
  
  export function FormattedTitle(value) {
    return value
      .replace(/-/g, " ")
      .split(" ")
      .map((item) => Capitalize(item))
      .join(" ");
  }
  
  export function convertFormula(str) {
    let st = "";
    if (str != "") {
      st = str.split(",");
      st = st.filter(Boolean);
      st = st.map((item) => (!isNaN(item) ? 110 : item));
      st = st.join("");
      st = st.includes("A") ? st.replace(/A/g, "") : st;
    }
    console.log("St", st);
    return st;
  }
  export function getCode(data, item) {
    return data.find((itm) => itm.apdM_Name === item)?.apdM_Code;
  }
  export function getPdDataCode(Type, Suffix) {
    return `${
      Type && Type !== undefined
        ? Type?.[0].toUpperCase() + Type?.split(" ")[1][0] + "-"
        : ""
    }${
      Suffix && Suffix !== undefined
        ? Suffix.split(" ") !== ""
          ? Suffix?.toUpperCase()
          : Suffix?.[0].toUpperCase()
        : ""
    }`;
  }
  export async function getProbeDescription(getPds, id) {
    let Posdescription = "";
    let PDS = await getPds(id).map(function(item) {
      return item["name"];
    });
    if (PDS && PDS.length > 0) {
      Posdescription =
        PDS.length >= 2
          ? `Check if ${PDS.splice(0, PDS.length - 1).toString()} and ${
              PDS[PDS.length - 1]
            } Revenue items are Lower due to Error.`
          : `Check if ${PDS.toString()} Revenue items are Lower due to Error.`;
    }
    return Posdescription;
  }
  export const nothing = () => {
    return 0;
  };
  
  export const tommorowDate = (date) => {
    let currentDate = new Date(date);
    return new Date(currentDate.setDate(currentDate.getDate() + 1));
  };
  
  const filterByType = (item, type) => ({
    menu_Id: type === "update" ? item.menu_Id : item.id,
    menu_Name: type === "update" ? item.menu_Name : item.name,
    view_Permission: type === "update" ? item.view_Permission : true,
    insert_Permission: type === "update" ? item.insert_Permission : true,
    update_Permission: type === "update" ? item.update_Permission : true,
    delete_Permission: type === "update" ? item.delete_Permission : true,
  });
  export const callPermissionByMenuCategory = (data, type) => {
    let updateMenus = [];
    data.forEach((item) => {
      updateMenus.push({
        label: item.mC_Name,
        open: false,
        menus: data
          .filter((it) => it.mC_Name === item.mC_Name)
          .map((item) => filterByType(item, type)),
      });
    });
    updateMenus = [
      ...new Map(updateMenus.map((it) => [it["label"], it])).values(),
    ];
    updateMenus = updateMenus.map((item) => {
      return {
        ...item,
        view_Permission:
          type === "update"
            ? item.menus.every((i) => i.view_Permission === true)
            : true,
        insert_Permission:
          type === "update"
            ? item.menus.every((i) => i.insert_Permission === true)
            : true,
        update_Permission:
          type === "update"
            ? item.menus.every((i) => i.update_Permission === true)
            : true,
        delete_Permission:
          type === "update"
            ? item.menus.every((i) => i.delete_Permission === true)
            : true,
      };
    });
    return updateMenus;
  };
  
  export const catchOtherStatusErrors = function(err) {
    let { errors } = err.response?.data || { errors: [] };
    let err_msg = "";
    let error = Object.entries(errors).map(([key, value]) => {
      return value[0];
    });
    err_msg = `${error.map((item) => item + `\n`).join(" ")}`;
    return err_msg;
  };
  
  export const compareDate = function(dateFrom, dateTo) {
    dateFrom = new Date(dateFrom);
    dateTo = new Date(dateTo);
    let [_month1, _year1, _date1] = [
      dateFrom.getMonth(),
      dateFrom.getFullYear(),
      dateFrom.getDate(),
    ];
    let [_month2, _year2, _date2] = [
      dateTo.getMonth(),
      dateTo.getFullYear(),
      dateTo.getDate(),
    ];
    console.log(`DateFrom:${_date1} MonthFrom:${_month1} , YearFrom:${_year1}`);
    console.log(`DateTo:${_date1} MonthTo:${_month1} , YearTo:${_year1}`);
    if (_year1 > _year2) {
      return true;
    }
    if (_month1 > _month2) {
      return true;
    }
    if (_date1 > _date1) {
      return true;
    }
    if (_month1 > _month2 || _year1 > _year2 || _date1 > _date1) {
      return true;
    }
    return false;
  };
  