import { Box, IconButton, InputBase } from "@material-ui/core";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import AppAxios, { Headers } from "../../../../axios";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
const styles = (theme) => ({
  root: {
    width: "95%",
    margin: "3%",
  },
  img:{
    width:'40px',
    height:'40px',
    objectFit:'contain'
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  rootx: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    margin: theme.spacing(2),
    border: "1px solid #e5e5e5",
    borderRadius: "10px",
  },
});
const ExpansionPanel = withStyles({
  root: {
    //border: '1px solid #fffff',
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
      boxShadow: "none",
    },
    "&:before": {
      display: "none",
      boxShadow: "none",
    },
    "&$expanded": {
      margin: "auto",
      boxShadow: "none",
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "#fffff",
    boxShadow: "none",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
      boxShadow: "none",
    },
  },
  content: {
    boxShadow: "none",
    "&$expanded": {
      margin: "12px 0",
      boxShadow: "none",
    },
  },
  expanded: {
    boxShadow: "none",
  },
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    boxShadow: "none",
  },
}))(MuiExpansionPanelDetails);

function Help(props) {
  const { classes } = props;
  const [help, setHelp] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [expanded, setExpanded] = useState("panel1");
  const [search, setSearch] = useState("");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    if (props?.menuData?.id) {
      AppAxios.get(`/LovServices/GetHelpLov?M_Id=${props.menuData.id}`, {
        headers: Headers(),
      })
        .then((res) => {
          if (res.data.statusCode === "200") {
            setHelp(res.data.data[0].lovHSCs);
          }
        })
        .catch((err) => {});
    }
  }, []);
  const handleSearch = (e) => {
    setSearch(e.target.value);
    help.length > 0 &&
      setFilteredData(
        help.filter(
          (item) =>
            item.hsC_Name
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            item.description
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
        )
      );
  };
  return (
    <div className={classes.root}>
      {help.length > 0 ? (
        <Box component="form" className={classes.rootx}>
          <IconButton className={classes.iconButton} aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            className={classes.input}
            value={search}
            onChange={handleSearch}
            placeholder="What Are You Looking For?"
          />
          {search !== "" && (
            <IconButton
              className={classes.iconButton}
              aria-label="search"
              onClick={() => setSearch("")}
            >
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      ) : (
        <div class="container">
          <div class="jumbotron">
            {/* <img
              alt="img"
              className={classes.img}
              src={toAbsoluteUrl("/media/svg/shapes/help.png")}
            /> */}
            
            <img alt="img" src="https://img.icons8.com/office/16/000000/help.png"/>
            {/* ${props.menuData.name}  */}
            <h1>{`There is no help for ${props.menuData.name}`}</h1>
          </div>
        </div>
      )}
      <div>
        {filteredData && filteredData.length > 0
          ? filteredData.map((item, index) => (
              <ExpansionPanel
                square
                expanded={expanded === index}
                onChange={handleChange(index)}
              >
                <ExpansionPanelSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <span className="svg-icon svg-icon-md">
                    <SVG
                      src={toAbsoluteUrl(
                        expanded === index
                          ? "/media/svg/icons/Navigation/Angle-double-down.svg"
                          : "/media/svg/icons/Navigation/Angle-double-right.svg"
                      )}
                    />
                  </span>
                  <div class="card-label text-dark pl-2">{item.hsC_Name}</div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))
          : help &&
            help.length > 0 &&
            help.map((item, index) => (
              <ExpansionPanel
                square
                expanded={expanded === index}
                onChange={handleChange(index)}
              >
                <ExpansionPanelSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <span className="svg-icon svg-icon-md">
                    <SVG
                      src={toAbsoluteUrl(
                        expanded === index
                          ? "/media/svg/icons/Navigation/Angle-double-down.svg"
                          : "/media/svg/icons/Navigation/Angle-double-right.svg"
                      )}
                    />
                  </span>
                  <div class="card-label text-dark pl-2">{item.hsC_Name}</div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ))}
      </div>
    </div>
  );
}

Help.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Help);
