import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authService";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  updatePersona: "[Updatepersona] Action",
  updateMember: "[updateMember] Action",
  updateinvitedMember: "[updateinvitedMember] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  invitedUser:undefined,
};

export const reducer = persistReducer(
  { storage, key: "v711-demo1-auth", whitelist: ["user", "authToken",] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.updatePersona: {
        const { persona } = action.payload;
        return {
          authToken: state.authToken,
          user: { ...state.user, persona },
        };
      }

      case actionTypes.updateMember: {
        const { isMember } = action.payload;

        return {
          authToken: state.authToken,
          user: { ...state.user, isMember },
        };
      }
      case actionTypes.updateinvitedMember: {
        // const { isMember } = action.payload;

        return {
          // authToken: state.authToken,
          invitedUser: { ...state.user },
        };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  updatePersona: (persona) => ({
    type: actionTypes.updatePersona,
    payload: { persona: persona },
  }),
  updateMember: (boolean) => ({
    type: actionTypes.updateMember,
    payload: {
      isMember: boolean,
    },
  }),
  updateinvitedMember: (boolean) => ({
    type: actionTypes.updateinvitedMember,
    payload: {
      isMember: boolean,
    },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    //const { data: user } = yield getUserByToken();
    // const user = yield getUserByToken();
    // yield put(actions.fulfillUser(user));
  });
}
