import { combineReducers } from "redux";
import { all } from "redux-saga/effects";
import * as auth from "./auth-redux/authRedux";
import { CompanyStepsReducer } from "./companyStepsReducer";
import { DiagnoseReducer } from "./diagnoseReducer";
import { PermissionReducer } from "./permissionReducer";
import {DiagnoseResultReducer} from "./diagnoseResultsReducer";
import {ReportReducer} from "./reportReducer";
import * as help from "../_metronic/layout/components/aside/aside-menu/helpRedux";
export const rootReducer = combineReducers({
  auth: auth.reducer,
  help:help.reducer,
  DiagnoseReducer: DiagnoseReducer,
  CompanyStepsReducer: CompanyStepsReducer,
  PermissionReducer:PermissionReducer,
  DiagnoseResultReducer:DiagnoseResultReducer,
  ReportReducer:ReportReducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
