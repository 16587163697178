import React from "react";
import { useField } from "formik";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import Select2 from "react-select";
import {useSelector} from 'react-redux';
const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control", "form-control-solid"];
  if (touched && errors) {
    classes.push("is-invalid-select");
  }
  if (touched && !errors) {
    classes.push("is-valid-select");
  }
  return classes.join(" ");
};
const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const borderStyles = {
  borderRadius: "4px",
  border: "solid 1px #707070",
};
const groupBadgeStyles = {
  backgroundColor: "#7D7D7D",
  // backgroundColor: '#EBECF0',
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};
const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.name}</span>
  </div>
);

export function Select({
  label,
  data,
  setOption,
  withFeedbackLabel = true,
  type = "text",
  customFeedbackLabel,
  children,
  ...props
}) {
  const [field, meta] = useField(props);
  const isOwner = useSelector((state) => state.CompanyStepsReducer.isOwner);
  const handleChange = (selectedOption) => {
    setOption(selectedOption);
  };
  const { touched, error } = meta;
  return (
    <div style={borderStyles}>
      <select
        className={getFieldCSSClasses(touched, error)}
        {...field}
        {...props}
        disabled={!isOwner}
      >
        {children}
      </select>
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          erros={error}
          touched={touched}
          label={label}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </div>
  );
}
const customStyles = {
  //   option: (provided) => ({
  //     ...provided,
  // backgroundColor:'#F4F5F8',
  //   }),
  control: (provided) => ({
    ...provided,
    backgroundColor: "#F4F5F8",
  }),
};

export function SelectSearch({
  label,
  data,
  value,
  setOption,
  Error,
  setError,
  withFeedbackLabel = true,
  type = "text",
  customFeedbackLabel,
  children,
  isDisabled,
  ...props
}) {
  const [field, meta] = useField(props);
  const isOwner = useSelector((state) => state.CompanyStepsReducer.isOwner);
  const handleChange = (selectedOption) => {
    setError(false);
    setOption(label, selectedOption);
  };
  const { touched, error } = meta;
  return (
    <>
      <Select2
        // defaultValue={colourOptions[1]}
        {...field}
        value={value}
        {...props}
        options={data}
        onChange={handleChange}
        formatGroupLabel={formatGroupLabel}
        styles={customStyles}
        isDisabled={isDisabled}
      />
      {Error && (
        <div
          className="feedback"
          style={{ fontSize: "12px", padding: "2px", color: "red" }}
        >
          {label && <>{label} is Required</>}
        </div>
      )}
      {!Error && withFeedbackLabel && (
        <FieldFeedbackLabel
          erros={error}
          touched={touched}
          label={label}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}

// export function CustomSelect({
//   label,
//   data,
//   value,
//   setOption,
//   Error,
//   setError,
//   withFeedbackLabel = true,
//   type = "text",
//   customFeedbackLabel,
//   children,
//   ...props
// }) {
//   const [field, meta] = useField(props);
//   const handleChange = (selectedOption) => {
//     setError(false);
//     setOption(label, selectedOption);
//   };
//   const { touched, error } = meta;
//   return (
//     <>
//       <Select2
//         // defaultValue={colourOptions[1]}
//         value={value}
//         {...props}
//         options={data}
//         onChange={handleChange}
//         formatGroupLabel={formatGroupLabel}
//         styles={customStyles}
//       />
//       {Error && (
//         <div
//           className="feedback"
//           style={{ fontSize: "12px", padding: "2px", color: "red" }}
//         >
//           {label && <>{label} is Required</>}
//         </div>
//       )}
//       {!Error && withFeedbackLabel && (
//         <FieldFeedbackLabel
//           erros={error}
//           touched={touched}
//           label={label}
//           customFeedbackLabel={customFeedbackLabel}
//         />
//       )}
//     </>
//   );
// };
