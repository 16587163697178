/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect, useState } from "react";
import objectPath from "object-path";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { FormattedTitle } from "../../components/aside/aside-menu/generic";
import { BreadCrumbs } from "./components/BreadCrumbs";
import FAQDialog from "../../../../app/common/aside/FAQModal";
import { useRoutesContext } from "../../../../app/routes/RoutesContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";

export function SubHeader(props) {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  const [title, setTitle] = useState("");
  const [menuData, setMenuData] = useState({});
  const RoutesContext = useRoutesContext();
  const userMenuPermissions = useSelector((state) => state.auth.user.userMenuPermissions);
  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    console.log({aside, header});
    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title
    );

    subheader.setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title
    );
    // eslint-disable-next-line
  }, [location || location.pathname]);

  var options = {
    //weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  var today = new Date();

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {
    if (
      location 
      && location.pathname !== "/" 
      //&& location.pathname !== "/welcome"
    ) {
          
      location.pathname.split("/").length > 2 && setTitle(FormattedTitle(location.pathname.split("/")[2]));
      location.pathname == "/paid-diagnosis/diagnosis/business-health" && setTitle("Business Health Dashboard"); //Added for #174

      //Added for Cosmetic Change #158 ana #159
      location.pathname.split("/").length > 1 && setTitle(FormattedTitle(location.pathname.split("/")[1]));
      location.pathname.split("/").length > 2 && setTitle(FormattedTitle(location.pathname.split("/")[2]));
      location.pathname == '/welcome' && setTitle('Home');
      location.pathname == '/pricing-plan/view' && setTitle('Pricing Plans');
      location.pathname == '/pricing-plan/edit' && setTitle('Edit Pricing Plan');
      location.pathname == '/company-profile/setup-profile' && setTitle('Setup Company Profile');
      location.pathname == '/company-profile/select-vsign-hms/vsign' && setTitle('Select Vital Signs');
      location.pathname == '/company-profile/select-vsign-hms/hms/vf' && setTitle('Select Finance Health Markers');
      location.pathname == '/company-profile/select-vsign-hms/hms/vo' && setTitle('Select Operations Health Markers');
      location.pathname == '/company-profile/select-vsign-hms/hms/vs' && setTitle('Select Sales & Marketing Health Markers');
      location.pathname == '/company-profile/select-vsign-hms/hms/vh' && setTitle('Select Human Capital Health Markers');
      location.pathname == '/company-profile/select-vsign-hms/hms/vt' && setTitle('Select IT & BI Health Markers');
      location.pathname == '/company-profile/select-vsign-hms/hms/vi' && setTitle('Select Innovation & Business Continuity Health Markers');
      location.pathname == '/company-profile/select-vsign-hms/hms/vg' && setTitle('Select Governance & Compliance Health Markers');
      location.pathname == '/company-profile/connection-bw-vs' && setTitle('Establish Connection Strength');
      location.pathname == '/company-profile/set-tolerance' && setTitle('Set Tolerance');
      location.pathname == '/company-profile/create-template/vf' && setTitle('Create Finance Template'); //Added for #187
      location.pathname == '/company-profile/create-template/vo' && setTitle('Create Operations Template');
      location.pathname == '/company-profile/create-template/vs' && setTitle('Create Sales & Marketing Template');
      location.pathname == '/company-profile/create-template/vh' && setTitle('Create Human Capital Template');
      location.pathname == '/company-profile/create-template/vt' && setTitle('Create Information Technology & BI Template');
      location.pathname == '/company-profile/create-template/vi' && setTitle('Create Innovation & Business Continuity Template');
      location.pathname == '/company-profile/create-template/vg' && setTitle('Create Governance & Compliance Template');
      location.pathname == '/company-profile/set-targets/vf' && setTitle('Set Finance Metrics Targets');
      location.pathname == '/company-profile/set-targets/vo' && setTitle('Set Operations Metrics Targets');
      location.pathname == '/company-profile/set-targets/vs' && setTitle('Set Sales & Marketing Metrics Targets');
      location.pathname == '/company-profile/set-targets/vh' && setTitle('Set Human Capital Metrics Targets');
      location.pathname == '/company-profile/set-targets/vt' && setTitle('Set Information Technology & Business Intelligence Metrics Targets');
      location.pathname == '/company-profile/set-targets/vi' && setTitle('Set Innovation & Business Continuity Metrics Targets');
      location.pathname == '/company-profile/set-targets/vg' && setTitle('Set Governance & Compliance Metrics Targets');
      location.pathname == '/company-diagnosis' && setTitle('Dashboard');
      location.pathname == '/diagnosis-period' && setTitle('Diagnosis Period');


      console.log('location:',location);
      location.pathname == '/pricing-plan/view' && setTitle('Customize Pricing Plan'); //Added for cosmetic issue #160
      location.pathname == '/pricing-plan/edit' && setTitle('Customize Pricing Plan'); //Added for cosmetic issue #160
      
      
      setMenuData(
        userMenuPermissions?.find((item) => item.alias === location.pathname)
      );
      console.log("menuData", menuData)
    }


 
  }, [subheader || location || location.pathname]);

  console.log("menuuu", menuData)
  return (
    <div
      id="kt_subheader"
      className={`subheader py-2 py-lg-4   ${layoutProps.subheaderCssClasses}`}
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}
        <div className="d-flex align-items-center flex-wrap mr-1">
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}

          <div className="d-flex align-items-baseline mr-5">
            <li className="breadcrumb-item">
              <Link to="/welcome">
                <i className="flaticon2-shelter text-muted icon-1x black" />
              </Link>
            </li>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip>
                  <span>
                    {menuData && menuData.description
                      ? menuData.description
                      : subheader.title}
                  </span>
                </Tooltip>
              }
            >
              <h5 className="text-dark font-weight-bold my-2 ml-5 mr-5">
               {/* <>{'title here'+title}</> */}
                <>{title}</> {/* Title fetching from here */}
                {/*<small></small>*/}
                {console.log({title})}
              </h5>
            </OverlayTrigger>
          </div>
        </div>

        {/* Toolbar */}
        <div className="d-flex align-items-center">
          {menuData && (
            <FAQDialog menuData={menuData} {...props} />
          )} 
          <a
            href="#"
            className="btn btn-light btn-sm font-weight-bold ml-2"
            id="kt_dashboard_daterangepicker"
            data-toggle="tooltip"
            title="Select dashboard daterange"
            data-placement="left"
          >
           {/*  <span
              className="text-muted font-weight-bold mr-2"
              id="kt_dashboard_daterangepicker_title"
            >
              Today
            </span> //Changed for Cosmetic Issue #160 */ }
            <span
              className="text-primary font-weight-bold"
              id="kt_dashboard_daterangepicker_date"
            >
              {today.toLocaleDateString("en-US", options)}
            </span>
          </a>
        </div>
      </div>
    </div>
  );
}

