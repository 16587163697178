import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { AsideMenuList } from "./AsideMenuList";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { HMAsideMenuList } from "./Stepper/HMAsideMenuList";
import { CTAsideMenuList } from "./Stepper/CTAsideMenuList";
import { STAsideMenuList } from "./Stepper/STAsideMenuList";
import { APAsideMenuList } from "./Diagnosis/APAsideMenuList";
import { DHMAsideMenuList } from "./Diagnosis/HMAsideMenuList";
import { RCAsideMenuList } from "./Diagnosis/RCSAsideMenuList";
import { SBAsideMenuList } from "./SBAsideMenuList";
import { WIAsideMenuList } from "./WIAsideMenuList";
import { BAAsideMenuList } from "./BAsideMenuList";
import { PDAsideMenuList } from "./PDAsideMenuList";
import { AMDAsideMenuList } from "./AMDAsideMenuList";
export function AsideMenu(props) {
  const { disableScroll, pageName } = props;
  const vsigns = useSelector((state) => state.PermissionReducer.vsigns);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true),
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        {MenuListRender(pageName, layoutProps, vsigns)}
      </div>
      {/* end::Menu Container */}
    </>
  );
}

const MenuListRender = (pageName, layoutProps,vsigns) => {
  switch (pageName) {
    case "HM":
      return <HMAsideMenuList layoutProps={layoutProps} vsigns={vsigns} />;
    case "CT":
      return <CTAsideMenuList layoutProps={layoutProps} vsigns={vsigns} />;
    case "ST":
      return <STAsideMenuList layoutProps={layoutProps} vsigns={vsigns} />;
    case "DAP":
      return <APAsideMenuList layoutProps={layoutProps} vsigns={vsigns} />;
    case "DHM":
      return <DHMAsideMenuList layoutProps={layoutProps} vsigns={vsigns} />;
    case "RCS":
      return <RCAsideMenuList layoutProps={layoutProps} vsigns={vsigns} />;
    case "SB":
      return <SBAsideMenuList layoutProps={layoutProps} vsigns={vsigns} />;
    case "WI":
      return <WIAsideMenuList layoutProps={layoutProps} vsigns={vsigns} />;
    case "BA":
      return <BAAsideMenuList layoutProps={layoutProps} vsigns={vsigns} />;
    case "PD":
      return <PDAsideMenuList layoutProps={layoutProps} vsigns={vsigns} />;
    case "AMD":
      return <AMDAsideMenuList layoutProps={layoutProps} vsigns={vsigns} />;
    default:
      return <AsideMenuList layoutProps={layoutProps} vsigns={vsigns} />;
  }
};
