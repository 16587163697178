import React, { createContext, useContext } from "react";

const SnackBarContext = createContext();

export function useSnackBarContext() {
  return useContext(SnackBarContext);
}

export function SnackBarUIProvider({ SnackBarEvents, children }) {
  const value = {
    position: SnackBarEvents.position,
    open: SnackBarEvents.open,
    setOpen:SnackBarEvents.setOpen,
    handleClose: SnackBarEvents.handleClose,
    duration: SnackBarEvents.duration,
    anchorOrigin: SnackBarEvents.anchorOrigin,
    message: SnackBarEvents.message,
    setMessage: SnackBarEvents.setMessage,
  };
 
  return (
    <SnackBarContext.Provider value={value}>
      {children}
    </SnackBarContext.Provider>
  );
}
