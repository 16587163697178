import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";

export const OVERALLREPORT="OVERALLREPORT"
export const PERIOD="PERIOD"
export const CONDUCTPERIOD="CONDUCTPERIOD"

//action creators

export function overallreport(data) {
  return {
    type: OVERALLREPORT,
    payload: data,
  };
}
export function Period(data) {
  return {
    type: PERIOD,
    payload: data,
  };
}
export function ConductPeriod(data) {
  return {
    type: CONDUCTPERIOD,
    payload: data,
  };
}

let vs = {
  
    overallvs:[],
    periodFrom:null,
    periodTo:null,
    diagnosisDate:null,
    conductperiodfrom:null,
    conductperiodto:null,
    isTruediagnosis:null,

};

export const ReportReducer = persistReducer(
  { storage, key: "v711-diagnose-result" },
  (state = vs, action) => {
    switch (action.type) {
 
          case OVERALLREPORT:
          return{
              ...state,
            overallvs:action.payload
          }
          case PERIOD:
            return{
                ...state,
                periodFrom:action.payload.periodFrom,
                periodTo:action.payload.periodTo,
                diagnosisDate:action.payload.diagnosisDate,
                isTruediagnosis:action.payload.isTruediagnosis

            }
            case CONDUCTPERIOD:
              return{
                  ...state,
                  conductperiodfrom:action.payload.conductperiodfrom,
                  conductperiodto:action.payload.conductperiodto
              }
            
      default:
        return state;
    }
  }
);
