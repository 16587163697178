/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { Divider } from "@material-ui/core";
import React from "react";
import SVG from "react-inlinesvg";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive, toAbsoluteUrl } from "../../../../../_helpers";

export function APAsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul
        className={`menu-nav data-auto-scroll="true" ${layoutProps.ulClasses}`}
      >
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/paid-diagnosis/diagnosis/alerts-probe-areas/fi",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink
            className="menu-link menu-toggle"
            to="/paid-diagnosis/diagnosis/alerts-probe-areas/fi"
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Sidebar/finance.svg")}
              />
            </span>
            <span className="menu-text">Finance</span>
          </NavLink>
        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/paid-diagnosis/diagnosis/alerts-probe-areas/op",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink
            className="menu-link menu-toggle"
            to="/paid-diagnosis/diagnosis/alerts-probe-areas/op"
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Sidebar/operations.svg")}
              />
            </span>
            <span className="menu-text">Operations</span>
          </NavLink>
        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/paid-diagnosis/diagnosis/alerts-probe-areas/sm",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink
            className="menu-link menu-toggle"
            to="/paid-diagnosis/diagnosis/alerts-probe-areas/sm"
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Sidebar/marketing.svg")}
              />
            </span>
            <span className="menu-text">Sales & Marketing</span>
          </NavLink>
        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/paid-diagnosis/diagnosis/alerts-probe-areas/it",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink
            className="menu-link menu-toggle"
            to="/paid-diagnosis/diagnosis/alerts-probe-areas/it"
          >
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Sidebar/it-bi.svg")} />
            </span>
            <span className="menu-text">IT & BI</span>
          </NavLink>
        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/paid-diagnosis/diagnosis/alerts-probe-areas/hc",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink
            className="menu-link menu-toggle"
            to="/paid-diagnosis/diagnosis/alerts-probe-areas/hc"
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Sidebar/human-capital.svg"
                )}
              />
            </span>
            <span className="menu-text">Human Capital</span>
          </NavLink>
        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/paid-diagnosis/diagnosis/alerts-probe-areas/ici",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink
            className="menu-link menu-toggle"
            to="/paid-diagnosis/diagnosis/alerts-probe-areas/ici"
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Sidebar/innovation-business.svg"
                )}
              />
            </span>
            <span className="menu-text">Innovation & Business Continuity</span>
          </NavLink>
        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/paid-diagnosis/diagnosis/alerts-probe-areas/gc",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink
            className="menu-link menu-toggle"
            to="/paid-diagnosis/diagnosis/alerts-probe-areas/gc"
          >
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Sidebar/govt.svg")} />
            </span>
            <span className="menu-text">Governance & Compliance</span>
          </NavLink>
        </li>
      </ul>
      {/* <Divider />
      <ul
        className={`menu-nav data-auto-scroll="true" ${layoutProps.ulClasses}`}
      >
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "#",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="#">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Sidebar/whats-new.svg")}
              />
            </span>
            <span className="menu-text">What's New</span>
          </NavLink>
        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "#",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="#">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Sidebar/help.svg")} />
            </span>
            <span className="menu-text">Help</span>
          </NavLink>
        </li>
      </ul> */}

      {/* end::Menu Nav */}
    </>
  );
}
