import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";

export const PULL_PERMISSION = "PULL_PERMISSION";
export const ADD_CMID = "ADD_CMID";
export const REPORTTILE = "REPORTTILE";
export const CLEAR = "CLEAR";
//action creators
export function updatePermission(data) {
  return {
    type: PULL_PERMISSION,
    payload: data,
  };
}
// export function clearPermission() {
//   return { type: CLEAR };
// }
export function reportTile(data) {
  return {
    type: REPORTTILE,
    payload: data,
  };}
let intialState = {
  permission: [],
  vsigns: [],
  report_tile:null
};
export const PermissionReducer = persistReducer(
  { storage, key: "v711-permission" },
  (state = intialState, action) => {
    switch (action.type) {
      case PULL_PERMISSION:
        return {
          ...state,
          permission: action.payload.menus,
          vsigns:action.payload.vsigns
        };
        case REPORTTILE:
          return {
            ...state,
            report_tile:action.payload
          };
      // case CLEAR:
      //   return intialState;
      default:
        return state;
    }
  }
);
