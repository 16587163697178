//import axios from "axios";

import AppAxios, { Headers } from "../../../axios";

export const MASTER_URL = "/CompanyMaster";
export const DiagnosebyCompany = "/GetDiagnoseMasterByCompany";
export const GetDiagnoseVS = "/LovServices/GetDiagnoseMasterByCM_DP";
export const GetMetricCalculation = "/LovServices/GetMetricCalculation";
export const LOVSERVICES = "/LovServices";

//Lovs
export const VSigns_URL = "/Vsign";

export function fetchDiagVSbyCompany(cmid, dpid) {
  return AppAxios.get(GetDiagnoseVS + `?CM_Id=${cmid}&DP_Id=${dpid}`, {
    headers: Headers(),
  });
}

export function getcompanymastertemplate(dmid, vsid, PDGID) {
  return AppAxios.get(
    `/LovServices/GetCompanyTemplateMasterLov?CM_ID=${dmid}&CVS_Id=${vsid}&PGD_Id=${PDGID}`,
    {
      headers: Headers(),
    }
  );
}

export function getcompanymastertemplateView(
  CVS_Id,
  CMID,
  PDG_Id,
  CDP_Id,
  NDP_Id,
  PDP_Id,
  YDP_Id
) {
  return AppAxios.get(
    `/LovServices/GetDiagnosePDView?CVS_Id=${CVS_Id}&PDG_Id=${PDG_Id}&CDP_Id=${CDP_Id}&NDP_Id=${NDP_Id}&PDP_Id=${PDP_Id}&YDP_Id=${YDP_Id}&CM_Id=${CMID}`,
    {
      headers: Headers(),
    }
  );
}

export function getpreviousdiagdata(dmid, vsid, PDGID, DP_Id) {
  return AppAxios.get(
    `/LovServices/GetDiagnosePDByDM_CVS_PDG?CM_ID=${dmid}&CVS_Id=${vsid}&PDG_Id=${PDGID}&DP_Id=${DP_Id}`,
    {
      headers: Headers(),
    }
  );
}
export function getDiagnosePDSAlert(CVS_Id,PDG_Id, DP_ID,CM_Id) {
  return AppAxios.get(
   
    `/LovServices/GetDiagnosePDsAlertByCM_DP?CVS_Id=${CVS_Id}&PDG_Id=${PDG_Id}&DP_ID=${DP_ID}&CM_Id=${CM_Id}`,
    {
      headers: Headers(),
    }
  );
}


export function getDiagnoseTemplate(dmid, cvsid, pdgid) {
  return AppAxios.get(
    `/LovServices/GetDiagnosePDByDM_CVS_PDG?DM_Id=${dmid}&CVS_Id=${cvsid}&PDG_Id=${pdgid}`,
    {
      headers: Headers(),
    }
  );
}

export function Postcomment(state) {
  return AppAxios.post("/CustomerAction", state, {
    headers: Headers(),
  });
}
export function CheckboxDisclaimer(state) {
  return AppAxios.post("/LovServices/AddCustomerSuggestion", state, {
    headers: Headers(),
  });
}
export function postdiagnosemastertemplate(state) {
  return AppAxios.post("/DiagnosePD", state, {
    headers: Headers(),
  });
}

export function import_temp(state) {
  console.log("stateee",state)
  return AppAxios.post("/CustomerImport/ImportExcelDocument", state, {
    headers: Headers(),
  });
}

export function export_temp(state) {
  return AppAxios.post("/CustomerDownload/DownloadExcelDocument", state, {
    headers: Headers(),
  });
}

export function download_report(  CM_ID,DP_Id,
  DM_ID,
  VS_ID,
  HM_ID,) {
  return AppAxios.get(`/RootCause/GetRootCauseReport?HM_Id=${HM_ID}&CM_Id=${CM_ID}&DM_Id=${DM_ID}&VS_Id=${VS_ID}&DP_Id=${DP_Id}`, {
    headers: Headers(),
  });
}
export function alert_status(state) {
  return AppAxios.post("/DiagnoseAlert", state, {
    headers: Headers(),
  });
}

export function update_PD(state) {
  return AppAxios.post("/LovServices/EditDiagnosePD", state, {
    headers: Headers(),
  });
}
export function getDiagnosePDSPeriod(state) {
  return AppAxios.post("/DiagnosePDsPeriod", state, {
    headers: Headers(),
  });
}

export function getBusinessHealth({ CMID,  DM_Id, PDP, CDP, NDP, YDP,VS_ID }) {
  let extended_url = VS_ID ? `&VS_Id=${VS_ID}` : "";
  return AppAxios.get(
    `/LovServices/GetBHMVS?CM_Id=${CMID}&DM_Id=${DM_Id}&PDP_Id=${PDP}&CDP_Id=${CDP}&NDP_Id=${NDP}&YDP_Id=${YDP}` +
      extended_url,

    {
      headers: Headers(),
    }
  );
}
export function getMetricCalculation({ CMID, PDP, CDP, NDP, YDP, DM_Id,PPID}) {
  console.log("AppAxios",AppAxios)
  return AppAxios.get(
    `${GetMetricCalculation}?CM_Id=${CMID}&PDP_Id=${PDP}&CDP_Id=${CDP}&NDP_Id=${NDP}&DM_Id=${DM_Id}&YDP_Id=${YDP}&PP_ID=${PPID}`,
    {
      headers: Headers(),
    }
  );
}
export function getBHMVSPD(state) {
  return AppAxios.post(`/LovServices/GetBHMVSPD`, state, {
    headers: Headers(),
  });
}
export function getcompanytolerence(CMID) {
  return AppAxios.get(
    `/LovServices/GetCompanyTolerance?CM_Id=${CMID}`,

    {
      headers: Headers(),
    }
  );
}

export function fetchRootCauseAxios(state) {
  console.log("state,state",state)
  return AppAxios.post(`/LovServices/GetBHMVSPDDetails`, state, {
    headers: Headers(),
  });
}
// export function fetchEEHM(state) {
//   return AppAxios.post(`/LovServices/GetEEHM`, state, {
//     headers: Headers(),
//   });
// }
export function GetCVsDetail(state) {
  return AppAxios.post(`/LovServices/GetCVSDetail`, state, {
    headers: Headers(),
  });
}

export function fetchVs(state) {
  return AppAxios.post(`/LovServices/GetBHMVSPDDetails`, state, {
    headers: Headers(),
  });
}

export function fetchRootCauseSuggestion(  CM_ID,
  DM_ID,
  VS_ID,
  HM_ID,) {
  return AppAxios.get(`/LovServices/GetHMSuggestion?HM_Id=${HM_ID}&CM_ID=${CM_ID}&DM_ID=${DM_ID}&VS_ID=${VS_ID}`, {
    headers: Headers(),
  });
}

export function GetReport(CMID,DMID,DPID) {
  console.log("axios",CMID,DMID,DPID)

  return AppAxios.get(`/LovServices/GetReport?CM_Id=${CMID}&DP_Id=${DPID}&DM_ID=${DMID}`, {
    headers: Headers(),
  });
}
export function getHMM({CM_Id,DM_Id,DP_Id,VS_Id}) {

  return AppAxios.get(`/LovServices/GeRootCauseReportDetails?CM_Id=${CM_Id}&DP_Id=${DP_Id}&DM_Id=${DM_Id}&VS_Id=${VS_Id}`, {
    headers: Headers(),
  });
}
export function fetchBM({CMID,vS_Id,PDP,CDP,NDP,YDP,DMID}) {
  return AppAxios.get(`/LovServices/GetCompanyTargetLov?CM_Id=${CMID}&VS_Id=${vS_Id}&PDP_ID=${PDP}&CDP_ID=${CDP}&NDP_ID=${NDP}&YDP_ID=${YDP}&DM_ID=${DMID}`, {
    headers: Headers(),
  });
}

export function fetchMetricbyVS({ CMID, DMID, DPID, VSID }) {
  return AppAxios.get(
    `${LOVSERVICES}/GetMetricsByVS?CM_Id=${CMID}&DM_Id=${DMID}&DP_Id=${DPID}&VS_Id=${VSID}`,
    {
      headers: Headers(),
    }
  );
}

export function fetchPDbyMetric({ CMID, DMID, DPID, MMID }) {
  return AppAxios.get(
    `${LOVSERVICES}/GetPDByMetric?CM_Id=${CMID}&DM_Id=${DMID}&DP_Id=${DPID}&MM_Id=${MMID}`,
    {
      headers: Headers(),
    }
  );
}
///LovServices​/EditDiagnosePD
