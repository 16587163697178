import AppAxios, { Headers } from "../../axios";

export const LOGIN_URL = "/Auth/CustomerLogin";
export const SIGN_UP = "/CustomerUser";
export const REGISTER_URL = "/CustomerUser";
export const REQUEST_PASSWORD_URL = "/Auth/ForgetPassword";
export const RESET_PASSWORD_URL = "/Auth/ResetPassword";

export const ME_URL = "api/me";

export function login(email, password,country) {
  return AppAxios.post(LOGIN_URL, { email, password,country });
}

export function signup(data) {
  return AppAxios.post(SIGN_UP, data);
}
export function invitedUserSignup(data, token) {
  return AppAxios.put(REGISTER_URL, data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token,
    },
  });
}
export function register(data) {
  return AppAxios.post(REGISTER_URL, data);
}

export function requestPassword(email) {
  return AppAxios.post(REQUEST_PASSWORD_URL + `?email=${email}`, {
    headers: Headers(),
  });
}
export function changepassword(state) {
  return AppAxios.post(RESET_PASSWORD_URL, state, { headers: Headers() });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return AppAxios.get(ME_URL);
}
