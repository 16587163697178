import {
  AppBar,
  Avatar,
  Button,
  Divider,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { HelpRounded, KeyboardArrowDown } from "@material-ui/icons";
import NotificationIcon from "@material-ui/icons/Notifications";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actions as ReduxAction } from "../../../redux/auth-redux/authRedux";
import {ClearCompanyStepsDetail} from '../../../redux/companyStepsReducer';
import {ClearDiagnoseDetail} from '../../../redux/diagnoseReducer';
import { ClearDiagnoseResult } from "../../../redux/diagnoseResultsReducer";
import { clearPermission } from "../../../redux/permissionReducer";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../_metronic/_helpers";
import "./main-header-diagnosis.styles.css";
import { SubHeader } from "../../../_metronic/layout/components/subheader/SubHeader";
const useStyles = makeStyles((theme) => ({
  headerMain: {
    backgroundColor: "#fff",
    boxShadow: "none",
  },
  divider: {
    width: 1,
    height: 30,
    backgroundColor: "#203843",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: "none",
  },
  toolbar: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    backgroundColor: "#ffff",
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
  notificationButton: {
    textTransform: "none",
    color: "#203843",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  tilteBtn: {
    fontSize: 14,
    color: "black ",
    fontWeight: "bold",
  },
  link: {
    position: "relative",
    display: "block",
    textDecoration: "none",
    "&:hover,&:focus,&:visited,&": {
      color: "#303F9F",
    },
  },
  avatar: {
    width: 35,
    height: 35,
    objectFit: "contain",
    backgroundColor: "#203843",
  },
  headerImg: {
    width: "5%",
    height: "auto",
  },
  headerLinks: {
    margin: "0",
    "& a": {
      color: "#707070",
    },
    "& li": {
      display: "inline",
      fontWeight: "600",
      marginLeft: "20px",
      fontSize: "14px",
    },
  },
  activeHeaderLinks: {
    color: "#fc681c",
    borderBottom: "2px solid #fc681c",
    paddingBottom: "20px",
  },
  activeTextStyle: {
    color: "#fc681c",
  },
}));
export default function MainHeaderDiagnosisNologo(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url) ? classes.activeHeaderLinks : "";
  };

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const Logout = (e) => {
    dispatch(ReduxAction.logout());
    dispatch(ClearDiagnoseDetail());
    dispatch(ClearCompanyStepsDetail());
    // dispatch(clearPermission());
    dispatch(ClearDiagnoseResult());
    history.push("/auth/login");
};
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const Dm_ID = useSelector((state) => state.DiagnoseReducer.dM_Id);
  const handleClick = (e) => {
    if (Dm_ID && Dm_ID != "") {
      e.preventDefault();
    }
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const menuId = "primary-search-account-menu";
  return (
    <AppBar position="static" className={classes.headerMain}> {/* Position changed to sticky from static for cosmetic issue #163 */}
      <Toolbar className={classes.toolbar}>
        {/* <img src={assets.Logo} className={classes.headerImg} /> */}

        <Divider
          variant="fullWidth"
          orientation="vertical"
          className={classes.divider}
        />
        <Typography className="web-header" style={{ flexGrow: 1 }}>
          <ul className={`menu-nav ${classes.headerLinks}`}>
            <li
              className={`menu-item  ${getMenuItemActive(
                "/paid-diagnosis/primary-data",
                false
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink
                className="menu-link menu-toggle"
                to="/paid-diagnosis/primary-data"
              >
                <span
                  className={`menu-text ${
                    checkIsActive(location, "/paid-diagnosis/primary-data")
                      ? classes.activeTextStyle
                      : ""
                  }`}
                >
                  Primary Data
                </span>
              </NavLink>
            </li>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/paid-diagnosis/diagnosis",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink
                className="menu-link menu-toggle"
                to="/paid-diagnosis/diagnosis"
               // onClick={handleClick}
              >
                <span
                  className={`menu-text ${
                    checkIsActive(location, "/paid-diagnosis/diagnosis")
                      ? classes.activeTextStyle
                      : ""
                  }`}
                >
                  Diagnosis
                </span>
              </NavLink>
            </li>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/paid-diagnosis/what-if-analysis",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink
                className="menu-link menu-toggle"
                to="/paid-diagnosis/what-if-analysis"
                style={{pointerEvents: 'none', color: "#bdb3b3"}}
                //onClick={handleClick}
              >
                <span
                  className={`menu-text ${
                    checkIsActive(location, "/paid-diagnosis/what-if-analysis")
                      ? classes.activeTextStyle
                      : ""
                  }`}
                >
                  What if Analysis
                </span>
              </NavLink>
            </li>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/paid-diagnosis/scenario-builder",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink
                className="menu-link menu-toggle"
                to="/paid-diagnosis/scenario-builder"
                style={{pointerEvents: 'none', color: "#bdb3b3"}}
                //onClick={handleClick}
              >
                <span
                  className={`menu-text ${
                    checkIsActive(location, "/paid-diagnosis/scenario-builder")
                      ? classes.activeTextStyle
                      : ""
                  }`}
                >
                  Scenario Builder
                </span>
              </NavLink>
            </li>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/paid-diagnosis/reports",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink
                className="menu-link menu-toggle"
                to="/paid-diagnosis/reports"
                //onClick={handleClick}
              >
                <span
                  className={`menu-text ${
                    checkIsActive(location, "/paid-diagnosis/reports")
                      ? classes.activeTextStyle
                      : ""
                  }`}
                >
                  Reports
                </span>
              </NavLink>
            </li>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/paid-diagnosis/benchmark",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink
                className="menu-link menu-toggle"
                to="/paid-diagnosis/benchmark"
               // onClick={handleClick}
              >
                <span
                  className={`menu-text ${
                    checkIsActive(location, "/paid-diagnosis/benchmark")
                      ? classes.activeTextStyle
                      : ""
                  }`}
                >
                  Benchmark
                </span>
              </NavLink>
            </li>
          </ul>
        </Typography>
        {/* <IconButton>
          <HelpRounded style={{ color: "#203843" }} />
        </IconButton>
        <IconButton>
          <NotificationIcon style={{ color: "#203843" }} />
        </IconButton>
        <Button
          className={classes.notificationButton}
          aria-controls="customized-menu"
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          startIcon={
            <Avatar alt="b" className={classes.avatar} sizes="5">
              {user.firstName.slice(0, 1).toUpperCase()}
            </Avatar>
          }
          // endIcon={<ExpandMoreIcon style={{ color: '#787879' }} />}
        >
          <p className="hide-with-drawer" style={{ margin: 0 }}>
            {`${user.firstName.toUpperCase()} ${user.lastName.toUpperCase()}`}
          </p>
          <KeyboardArrowDown style={{ color: "#203843" }} />
        </Button>
        <Menu
          elevation={0}
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          id={menuId}
          keepMounted
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={Logout}>
            <Typography className={classes.tilteBtn}>Logout</Typography>
          </MenuItem>
        </Menu> */}
      </Toolbar>
      <SubHeader/>
    </AppBar>
  );
}
