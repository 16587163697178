import React from "react";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import {useSelector} from 'react-redux';

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function Input({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  ...props
}) {
  console.log("propsss",props,field)
  console.log("typing contact name",props,field)
  const isOwner = useSelector((state) => state.CompanyStepsReducer.isOwner);
  return (
    <div>
    <div className="row">
      <div className="col-lg-1 col-12 col-md-3">
        {label && <label>{label}</label>}
      </div>
      <div className="col-lg-12 col-12 col-md-3">
        <input
          style={{ backgroundColor: "#F4F5F8", color: "#000000" }}
          type={type}
          className={getFieldCSSClasses(
            touched[field.name],
            errors[field.name]
          )}
          {...field}
          {...props}
          // disabled={isOwner?!isOwner:false}
        />
        {withFeedbackLabel && (
          <FieldFeedbackLabel
            error={errors[field.name]}
            touched={touched[field.name]}
            label={label}
            type={type}
            customFeedbackLabel={customFeedbackLabel}
          />
        )}
      </div>
    </div>
  </div>
  );
}
