// import swal from "sweetalert";
import swal from "sweetalert";
import { catchOtherStatusErrors } from "../../utils/generic";
import * as requestFromServerDiag from "../disgnose/diagnose-axios";
import * as requestFromServer from "./company-axios";
import { PERIOD } from "../../../redux/reportReducer";
import { keyBy } from "lodash";

export const fetchMasters = (setData, setLoading) => {
  setLoading(true);
  return requestFromServer
    .getAllmasters()
    .then((response) => {
      if (response.data.statusCode === "200") {
        setData(response.data.data.sort((a, b) => b.id - a.id));
        setLoading(false);
      } else {
        setLoading(false);
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find masters";
      setLoading(false);
    });
};
export const fetchMaster = (id, sno, setActionsLoading, setMasterForEdit) => {
  if (!id) {
    setActionsLoading(false);
    setMasterForEdit(undefined);
    return;
  }
  setActionsLoading(true);
  return requestFromServer
    .getmasterById(id)
    .then((response) => {
      const master = response.data.data;
      setMasterForEdit({ ...master, sno: sno });
      setActionsLoading(false);
    })
    .catch((error) => {
      error.clientMessage = "Can't find master";
      setActionsLoading(false);
    });
};

export const getCompMasById = (id, setCompanyMasterEdit) => {
  return requestFromServer.getCompanyMasterById(id).then((res) => {
    if (res.data.statusCode === "200") {
      console.log("res.data.data master edit",res.data.data)
      setCompanyMasterEdit(res.data.data);
    return (res.data.data.cnT_Id);
    }
  });
};

export const getCompMasByIdV1 = (id, setCompanyUser) => {
  return requestFromServer.getCompanyMasterById(id).then((res) => {
    if (res.data.statusCode === "200") {
      console.log("res.data.data master edit",res.data.data)
      const emails = [];
      res.data.data.companyPersons.map((person) => emails.push({ label: person.email, value: person.email }));
      setCompanyUser(emails);
      //setCompanyMasterEdit(res.data.data.companyPersons);
    return (res.data.data.cnT_Id);
    }
  });
};

export const getCVSbyCompany = (id, setData, vsigns, setVsignOptions) => {
  return requestFromServer.fetchCVSbyCompany(id).then((res) => {
    if (res.data.statusCode === "200") {
      let data = res.data.data.map((item) => ({
        cvS_Id: item.id,
        ...item.vsign[0],
      }));
      data.sort((a, b) => a.sequence - b.sequence);
      localStorage.setItem("selectedVsign", JSON.stringify(data));

      setData(data);

      // vsigns.length == 0
      //   ? setData(data)
      //   : setData(intersectedValue(data, vsigns));
      setVsignOptions &&
        setVsignOptions(
          data.map((item) => ({
            label: item.vS_Name,
            value: item.vS_Id,
            vS_Id: item.vS_Id,
            vS_Name: item.vS_Name,
          }))
        );
    }
  });
};
export const getInvited = (id, setData, vsigns, setVsignOptions) => {
  
  return requestFromServer.fetchInvitedVsign(id).then((res) => {
    if (res.data.statusCode === "200") {
      let data = res.data.data.map((item) => ({
        cvS_Id: item.id,
        ...item.vsign[0],
      }));
      data.sort((a, b) => a.sequence - b.sequence);
      localStorage.setItem("selectedVsign", JSON.stringify(data));

      setData(data);

      // vsigns.length == 0
      //   ? setData(data)
      //   : setData(intersectedValue(data, vsigns));
      setVsignOptions &&
        setVsignOptions(
          data.map((item) => ({
            label: item.vS_Name,
            value: item.vS_Id,
            vS_Id: item.vS_Id,
            vS_Name: item.vS_Name,
          }))
        );
    }
  });
};

export const fetchVSigns = async (setVSigns, setUpdate, setLoading, CMID) => {
  setLoading(true);
  return requestFromServer
    .fetchVSigns()
    .then((res) => {
      if (res.data.statusCode === "200") {
        let vsignsList = res.data.data
          .sort((a, b) => a.sequence - b.sequence)
          .map(({ id, name, code, icon, sequence, active }) => ({
            id,
            vS_Id: id,
            icon,
            code,
            name,
            sequence,
            state: false,
            active,
          }));
        const vsigns = [];
        vsignsList.map((vs) => {
          if (vs.active) vsigns.push(vs);
        });
        if (CMID) {
          return requestFromServer
            .fetchCompanyVSignsByDMID(CMID)
            .then((resp) => {
              setLoading(false);
              if (resp.data.statusCode === "200") {
                setVSigns(
                  vsigns.map((itm) =>
                    resp.data.data.companyVSigns.some(
                      (item) => item.vS_Id === itm.vS_Id
                    )
                      ? { ...itm, state: true }
                      : { ...itm }
                  )
                );
                setUpdate(true);
              } else {
                setLoading(false);
                setVSigns(vsigns);
              }
            });
        }
        setLoading(false);
        setVSigns(vsigns);
      }
    })
    .catch((err) => {
      setLoading(false);
    });
};
export const fetchVSignsPaidDiag = async (
  setVSigns,
  id,
  setLoading,
  setUpdate,
  setID,
  DPID,
  permVsign,isOwner
  ,selectedVSignForCMID
) => {
  setLoading(true);
  return requestFromServer
    .fetchVSigns()
    .then((res) => {
      if (res.data.statusCode === "200") {
        let vsigns
       if(isOwner) {
          vsigns = res.data.data
          .sort((a, b) => a.sequence - b.sequence)
          .map(({ id, name, code, icon, sequence }) => ({
            id: id,
            vS_Id: id,
            icon: icon,
            code: code,
            name: name,
            sequence: sequence,
            state: false,
            source: "",
          }));}
          else
          {
             vsigns =permVsign
          .sort((a, b) => a.sequence - b.sequence)
          .map(({ vS_Id, vS_Name, code, icon, sequence }) => ({
            id: vS_Id,
            vS_Id: vS_Id,
            icon: icon,
            code: code,
            name: vS_Name,
            sequence: sequence,
            state: false,
            source: "",
          }));
          }

        return requestFromServer.fetchCompanyVSignsByDMID(id).then((resp) => {
          if (resp.data.statusCode === "200") {
            return requestFromServerDiag
              .fetchDiagVSbyCompany(id, DPID)
              .then((re) => {
                console.log("d2", vsigns);
                setLoading(false);
                if (re.data.statusCode === "200") {
                  setUpdate(true);
                  setID(re.data.data.id);
                  let d = re.data.data.diagnoseDetails;
                  setVSigns(
                    vsigns.map((itm) =>
                      d.find((it) => it.vS_Id === itm.vS_Id)
                        ? {
                            ...itm,
                            ...d.find((it) => it.vS_Id === itm.vS_Id),
                            state: true,
                            source: "excel"
                          }
                        : { ...itm, source: "excel" }
                    )
                  );
                  console.log("after set", vsigns);
                  return;
                } else {
                  setVSigns(vsigns.map((itm) => {
                    return { ...itm, source: "excel", state: selectedVSignForCMID.includes(itm.vS_Id) ? true : (
                      resp.data.data.companyVSigns.some(
                        (item) => item.vS_Id === itm.vS_Id
                      ) ? true : false
                    ) }
                  }));
                  console.log("else", vsigns);
                }
              })
              .catch((error) => {
                console.log(error);
                setLoading(false);
              });
          }
        });
      }
    })
    .catch((err) => {
      setLoading(false);
    });
};
export const fetchCVsigns = async (
  setVsigns,
  setselectedVSigns,
  setLoading,
  CMID
) => {
  return requestFromServer
    .fetchCVSbyCompany(CMID)
    .then((response) => {
      if (response.data.statusCode === "200") {
        let data = response.data.data.map((item) => ({
          cvS_Id: item.id,
          ...item.vsign[0],
        }));
        //setLoading(false);
        console.log("data in cs",data)
        setVsigns(
          data.filter((i)=>i.vS_Id!=88).map(({ cvS_Id, vS_Id, vS_Name }) => ({
            cvsC_Id: cvS_Id,
            cvsB_Id: cvS_Id,
            vsC_Id: vS_Id,
            vsB_Id: vS_Id,
            vs_Name: vS_Name,
            vsC_Name: vS_Name,
            vsB_Name: vS_Name,
            stateUpdate: false,
            connectionStrength: 0,
          }))
        );
        // setselectedVSigns(
        //   response.data.data.map(({ cvS_Id, vS_Id, vS_Name }) => ({
        //     cvsC_Id: cvS_Id,
        //     cvsB_Id: cvS_Id,
        //     vsC_Id: vS_Id,
        //     vsB_Id: vS_Id,
        //     vs_Name: vS_Name,
        //     vsC_Name: vS_Name,
        //     vsB_Name: vS_Name,
        //     stateUpdate: false,
        //     connectionStrength: 0,
        //   }))
        // );
      }
    })
    .catch((error) => {
      //setLoading(false);
      error.clientMessage = "Can't find vsign's";
    });
};

export const postDiagnoseMaster = (data, setSubmitLoading, onSuccess) => {
  setSubmitLoading(true);
  return requestFromServer
    .postDiagnoseMaster(data)
    .then((res) => {
      setSubmitLoading(false);
      if (res.data.statusCode === "200") {
        swal("Success", "Submitted Successfully", "success", {
          buttons: {          
            catch: {
              text: "OK",
            },
          },
        })
        .then(() => {
              swal("Note", "All the fields left blank will be considered as zero.");
          }
        );
        onSuccess();
      }
    })
    .catch((err) => setSubmitLoading(false));
};

export const putDiagnoseMaster = (data, setSubmitLoading, onSuccess) => {
  setSubmitLoading(true);
  return requestFromServer
    .putDiagnoseMaster(data)
    .then((res) => {
      setSubmitLoading(false);
      if (res.data.statusCode === "200") {
        swal("Success", "Submitted Successfully","success", {
          buttons: {          
            catch: {
              text: "OK",
            },
          },
        })
        .then(() => {
          swal("Note", "All the fields left blank will be considered as zero.");
          }
        );
        onSuccess();
      }
    })
    .catch((err) => setSubmitLoading(false));
};

export const postAllDiagnosePeriods = (data, companyMasterId, periodMasterId, setSubmitLoading) => {
  setSubmitLoading(true);
  return requestFromServer
    .postAllDiagnosePeriod(data, companyMasterId, periodMasterId)
    .then((res) => {
      setSubmitLoading(false);
      if (res?.status === 200) {
        swal("Success", "Submitted Successfully.");
        return (res.data);
      } else if (res?.status === 302) {
        swal("Success", "success");
      }
    }).catch((err) => { 
      console.log("err", err);
      if(err.response?.status === 409) {
        swal("Warning", "Record Already Present.");
      } else if (err.response?.status === 400) {
        swal("Failed", "Either wrong periodTo date or wrong inputs.");
      } else if(err.response?.status === 500) {
        swal("Failed", "Api failed to register periods.");
      } else {
        swal("Failed", "Something went wrong. Please try again.");
      }
      setSubmitLoading(false);
   });
};

export const postDiagnosePeriod = async (data, setSubmitLoading, c,test,dispatch) => {
  console.log('===========================');
  console.log('data - postDiagnosePeriod :: ', data);
  console.log('===========================');
  setSubmitLoading(true);
  return requestFromServer
    .postDiagnosePeriod(data)
    .then(async (res) => {
      setSubmitLoading(false);
      if (res.data.statusCode === "200") {
        c && swal("Success", "Submitted Successfully");
        console.log("data 1",res.data.data.id,res.data.data.name,res.data.data.type,res.data.data.year)
        console.log("Period From",res.data.data.periodFrom)
        dispatch({
          type: PERIOD,
          payload:res.data.data,
        })
       
      test(res.data.data.id)
        return {
          id: res.data.data.id,
          Name: res.data.data.name,
          Type: res.data.data.type,
          Sequence: res.data.data.sequence,
          year: res.data.data.year,
          // adP_Id:res.data.data,
          
          
        };
        

      } else if (res.data.statusCode === "302") {
        console.log("in 302")
        c && swal("Success", res.data.message, "success");
         console.log("after sucess 302")
         dispatch({
          type: PERIOD,
          payload:res.data.data,
        })
        
          console.log("data 302",res.data.data.id,res.data.data.name,res.data.data.type,res.data.data.year)
          test(res.data.data.id)
        return ({
          id: res.data.data.id,
          Name: res.data.data.name,
          Type: res.data.data.type,
          Sequence: res.data.data.sequence,
          year: res.data.data.year,
          // adP_Id:res.data.data,
          
        })


      }

    }
   
    )
    .catch((err) => { console.log("err",err)
      setSubmitLoading(false)
   });
};
export const postthenDiagnosePeriod = async (data, setSubmitLoading) => {
  console.log('===========================');
  console.log('data - postthenDiagnosePeriod :: ', data);
  console.log('===========================');
  setSubmitLoading(true);
  return requestFromServer
    .postDiagnosePeriod(data)
    .then(async (res) => {
      setSubmitLoading(false);
      if (res.data.statusCode === "200") {
       
        console.log("data 1",res.data.data.id,res.data.data.name,res.data.data.type,res.data.data.year)

 
     
        return {
          id: res.data.data.id,
          Name: res.data.data.name,
          Type: res.data.data.type,
          Sequence: res.data.data.sequence,
          year: res.data.data.year,
          // adP_Id:res.data.data,
          
          
        };
        

      } else if (res.data.statusCode === "302") {
        console.log("in 302")
        
         console.log("after sucess 302")
        
          console.log("data 302",res.data.data.id,res.data.data.name,res.data.data.type,res.data.data.year)
       
        return ({
          id: res.data.data.id,
          Name: res.data.data.name,
          Type: res.data.data.type,
          Sequence: res.data.data.sequence,
          year: res.data.data.year,
          // adP_Id:res.data.data,
          
        })


      }

    }
   
    )
    .catch((err) => { console.log("err",err)
      setSubmitLoading(false)
   });
};

export const putDiagnosePeriod = async (data, setSubmitLoading, c) => {
  console.log('===========================');
  console.log('data - putDiagnosePeriod :: ', data);
  console.log('===========================');
  setSubmitLoading(true);
  return requestFromServer
    .putDiagnosePeriod(data)
    .then(async (res) => {
      setSubmitLoading(false);
      if (res.data.statusCode === "200") {
        c && swal("Success", "Submitted Successfully");
   
      console.log("adp id inputdiag",res.data.data.data.id)
        // return ({
        //   id: res.data.data.id,
        //   Name: res.data.data.name,
        //   Type: res.data.data.type,
        //   Sequence: res.data.data.sequence,
        //   year: res.data.data.year,
        //   adP_Id:res.data.data.adP_Id,
        //   id:res.data.data.id,
        // });

      } else if (res.data.statusCode === "302") {
        c && swal("Success", res.data.message, "success");

        // return {
        //   id: res.data.data.id,
        //   Name: res.data.data.name,
        //   Type: res.data.data.type,
        //   Sequence: res.data.data.sequence,
        //   year: res.data.data.year,
        //   // adP_Id:res.data.data.adP_Id
        // };
      }
      
    }
   
    )
    .catch((err) => setSubmitLoading(false));
};

export const fetchIndustryClusters = (id, setIndustryClusters) => {
  return requestFromServer
    .fetchIndustryCluster(id)
    .then((response) => {
      if (response.data.statusCode === "200") {
        setIndustryClusters(response.data.data);
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Industry Clusters";
    });
};

export const fetchIndustryType = (setIndustryType) => {
  return requestFromServer
    .fetchIndustryType()
    .then((response) => {
      if (response.data.statusCode === "200") {
        setIndustryType(
          response.data.data.map(({ id, name }) => ({
            iT_Id: id,
            iT_Name: name,
            value: id,
            label: name,
          }))
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Industry Clusters";
    });
};
export const fetchUserCompany = (setUserCompany, title,cmid) => {
  console.log("cmid",cmid,title)
  return requestFromServer
    .getUserCompany(title,cmid)
    .then((response) => {
      if (response.data.statusCode === "200") {
        setUserCompany(
          response.data.data.map(
            ({
              cM_Id,
              dM_Id,
              cU_Id,
              yearEnd,
              name,
              isOwner,
              financialValuesIn,
              users
            }) => ({
              financialValuesIn,
              cM_Id,
              dM_Id,
              cU_Id,
              isOwner,
              yearEnd,
              name,
              label: name,
              value: name,
              users
            })
          )
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Companies";
    });
};

export const fetchCompanyUser = (setCompanyUser, cmid) => {
  return requestFromServer
    .getCompanyUser()
    .then((response) => {
      if (response.data.statusCode === "200") {
        const emails = [];
        response.data.data.filter(({cM_Id}) => cM_Id === cmid).map(({email}) => emails.push({ label: email, value: email }));
        setCompanyUser(emails);
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Companies";
    });
};

export const fetchBusinessType = (setBusinessType) => {
  return requestFromServer
    .fetchBusinessType()
    .then((response) => {
      if (response.data.statusCode === "200") {
        setBusinessType(
          response.data.data.map(({ id, name }) => ({
            bT_Id: id,
            bT_Name: name,
            label: name,
            value: id,
          }))
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Business Type";
    });
};

export const fetchCity = (c_id, setCity) => {
  return requestFromServer
    .fetchCities(c_id)
    .then((response) => {
      if (response.data.statusCode === "200") {
        setCity(
          response.data.data.map(({ id, name }) => ({
            c_Id: id,
            c_Name: name,
            label: name,
            value: id,
          }))
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Cities";
    });
};
export const fetchPermission = (c_id, setData,onsucces) => {
  return requestFromServer
    .fetchCompanyUserPermission(c_id)
    .then((response) => {
      if (response.data.statusCode === "200") {
        setData(response.data.data);
        onsucces(response.data.data)
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Cities";
    });
};
export const fetchCountry = (setCountry) => {
  return requestFromServer
    .fetchCountry()
    .then((response) => {
      if (response.data.statusCode === "200") {
        setCountry(
          response.data.data.map(({ id, name }) => ({
            cnT_Id: id,
            cnT_Name: name,
            label: name,
            value: id,
          }))
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Countries";
    });
};
export const fetchRegionCountry = (setRegionCountry) => {
  return requestFromServer
    .fetchRegionCountry()
    .then((response) => {
      if (response.data.statusCode === "200") {
        setRegionCountry(
          response.data.data.map(({ id, name }) => ({
            cntR_Id: id,
            cntR_Name: name,
            label: name,
            value: id,
          }))
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Countries";
    });
};
export const fetchCompanyVsignById = (dmID, setCVSigns, setLoading) => {
  return requestFromServer
    .fetchCompanyVSignsByDMID(dmID)
    .then((resp) => {
      if (resp.data.statusCode === "200") {
        setCVSigns(resp.data.data.companyVSigns);
        setLoading(false);
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Company Vsigns";
    });
};
export const fetchCompanyAge = (setCompanyAge) => {
  return requestFromServer
    .fetchCompanyAge()
    .then((response) => {
      if (response.data.statusCode === "200") {
        setCompanyAge(
          response.data.data.map(({ id, name }) => ({
            cA_Id: id,
            cA_Name: name,
            label: name,
            value: id,
          }))
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Company Age";
    });
};
export const fetchCompanySize = (setCompanSize) => {
  return requestFromServer
    .fetchCompanySize()
    .then((response) => {
      if (response.data.statusCode === "200") {
        setCompanSize(
          response.data.data.map(({ id, name, description }) => ({
            cS_Id: id,
            cS_Name: name,
            label: name + "/" + description || "",
            value: id,
          }))
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Company Size";
    });
};
export const fetchHealthMarkerWRVsignDefault = ( vS_Id,
  setLoading,
  setHm,
  setIsAlready,
  cvS_Id,
  CMID) => {
  return requestFromServer
    .fetchHealthMarkerWRVsign(CMID, vS_Id)
    .then((res) => {
      if (res.data.statusCode === "200") {
        let hms = res.data.data.map(({ id, name,defaultVSign }) => ({
          hM_Id: id,
          hM_Name: name,
          state: defaultVSign,
        }));
        setHm(hms);
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Company Size";
    });
};
export const fetchHealthMarkerWRVsign = ( vS_Id,
  setLoading,
  setHm,
  setIsAlready,
  cvS_Id,
  CMID,) => {
  return requestFromServer
    .fetchCompanyHM(CMID, cvS_Id)
    .then((res) => {
      if (CMID) {
        return requestFromServer.fetchCompanyHM(CMID, cvS_Id).then((resp) => {
          setLoading(false);
          if (resp.data.statusCode === "200" ) {
            console.log("resp.data.message",resp.data.message)
            setIsAlready(true);
            

            setHm(
              // hms.map((itm) =>
              //   resp.data.data.some((item) => item.id === itm.hM_Id)
              //     ? { ...itm, state:true }
              //     : { ...itm}))}
          // resp.data.data.filter((val)=>val.id===itm.hM_Id).map((it)=>
          //   // console.log("it",it," ")
          // (    it.id )
          //      )
           resp.data.data.map(({ id, name,defaultVSign }) => ({
            hM_Id: id,
            hM_Name: name,
            state: defaultVSign,
          })));
            }

        });
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Company Size";
    });
};
// export const fetchHealthMarkerWRVsign = async (
//   vS_Id,
//   setLoading,
//   setHm,
//   setIsAlready,
//   cvS_Id,
//   CMID
// ) => {
//   setLoading(true);

//   return requestFromServer
//     .fetchHealthMarkerWRVsign(CMID, vS_Id)
//     .then((res) => {
//       if (res.data.statusCode === "200") {
//         let hms = res.data.data.map(({ id, name,defaultVSign }) => ({
//           hM_Id: id,
//           hM_Name: name,
//           state: defaultVSign,
//         }));
//         if (CMID) {
//           return requestFromServer.fetchCompanyHM(CMID, cvS_Id).then((resp) => {
//             setLoading(false);
//             if (resp.data.statusCode === "200" && resp.data.message>0) {
//               console.log("resp.data.message",resp.data.message)
//               setIsAlready(true);
//               console.log("hms",hms)

//               setHm(
//                 // hms.map((itm) =>
//                 //   resp.data.data.some((item) => item.id === itm.hM_Id)
//                 //     ? { ...itm, state:true }
//                 //     : { ...itm}))}
//             // resp.data.data.filter((val)=>val.id===itm.hM_Id).map((it)=>
//             //   // console.log("it",it," ")
//             // (    it.id )
//             //      )
//              resp.data.data.map(({ id, name,defaultVSign }) => ({
//               hM_Id: id,
//               hM_Name: name,
//               state: defaultVSign,
//             })));
//               }
//             else if( resp.data.message==0) {
//               console.log("in elsedddddddd")
//               setHm(hms);
//             }
//           });
//         }
//         setLoading(false);
//         setHm(hms);
//       }
//     })
//     .catch((err) => {
//       setLoading(false);
//     });
// };


// export const fetchConnectedVSigns = (CM_ID, setCVSigns, setLoading) => {
//   setLoading(true);
//   return requestFromServer
//     .fetchVSignsByItID(CM_ID)
//     .then((res) => {
//       if (res.data.statusCode === "200") {
//         setLoading(false);
//         setCVSigns(res.data.data.connectionStrengths);
//       }
//     })
//     .catch((err) => {
//       setLoading(false);
//     });
// };
export const fetchConnectedVSignsByCMID = (
  CM_Id,
  setCVSigns,
  setUpdate,
  setLoading
) => {
  console.log("in api of connected vsigns")
  setLoading(true);
  return requestFromServer
    .fetchCVSignsByDMID(CM_Id)
    .then((res) => {
      setLoading(false);
      if (res.data.statusCode === "200") {
        setLoading(false);
        setUpdate(true);
        let data = res.data.data.companyVSignCSDetails;
        console.log("sdatatate",data)
        return setCVSigns((s) =>
       
          s.map((item) => {
           
            let obj = data.find(
              (itm) =>
                itm.cvsB_Id === item.cvsB_Id && itm.cvsC_Id === item.cvsC_Id
                   );
            if (obj) {
              console.log("item",item)
              console.log("obj",obj)
              return {
                ...item,
                connectionStrength: obj.connectionStrength,
              };
            } else {
              return { ...item };
            }
          })
        );
      }
    })
    .catch((err) => {
      console.log(err)
      setLoading(false);
    });
};
export const fetchVSignsByICID = (CM_ID, setCVSigns, setLoading) => {
  console.log("api call")
  return requestFromServer
    .fetchVSignsByICID(CM_ID)
    .then((res) => {
      setLoading(false);
      if (res.data.statusCode === "200") {
        let data = res.data.data;
console.log( "api fgfff ",res.data.data)
        setCVSigns(
          (state) =>
        
            (data = state.map((item) => {
              let obj = data.find(
                (itm) =>
                  itm.vsB_Id === item.vsB_Id && itm.vsC_Id === item.vsC_Id
              );

              if (obj) {
                return {
                  ...item,
                  connectionStrength: obj.connectionStrength,
                };
              } else {
                return { ...item };
              }
            }))
        );
      }
    })
    .catch((err) => {
      setLoading(false);
    });
};
export const fetchRegionMaster = (setRegionMaster) => {
  return requestFromServer
    .fetchRegionMaster()
    .then((response) => {
      if (response.data.statusCode === "200") {
        setRegionMaster(
          response.data.data.map(({ id, name }) => ({
            rM_Id: id,
            rM_Name: name,
            label: name,
            value: id,
          }))
        );
      }
    })
    .catch((error) => {
      error.clientMessage = "Can't find Region";
    });
};

export const deleteMaster = (id, setMasterLoading, onHide, setLoading) => {
  setLoading(true);
  return requestFromServer
    .deletemaster(id)
    .then((response) => {
      if (response.data.statusCode === "200") {
        setMasterLoading(true);
        onHide();
        setLoading(false);
        return;
      }
      swal("warning", response.data.message, "warning");
      setLoading(false);
    })
    .catch((error) => {
      error.clientMessage = "Can't create master";
      setLoading(false);
    });
};
export const postCompanyCVs = (state, setLoading, onSucess, btnTitle) => {
  setLoading(true);
  return requestFromServer
    .postCompanyCVs(state)
    .then((response) => {
      setLoading(false);
      if (response.data.statusCode === "200") {
        onSucess(btnTitle);
        return;
      }
      swal("warning", response.data.message, "warning");
      setLoading(false);
    })
    .catch((error) => {
      error.clientMessage = "Can't create master";
      setLoading(false);
    });
};
export const addCompanyHm = (state, setLoading, onSucess) => {
  setLoading(true);
  return requestFromServer
    .addCompanyHm(state)
    .then((response) => {
      setLoading(false);
      if (response.data.statusCode === "200") {
        onSucess();
        return;
      }
      swal("warning", response.data.message, "warning");
      setLoading(false);
    })
    .catch((error) => {
      error.clientMessage = "Can't create master";
      setLoading(false);
    });
};
export const updateCompanyHm = (state, setLoading, onSucess) => {
  setLoading(true);
  return requestFromServer
    .updateCompanyHm(state)
    .then((response) => {
      if (response.data.statusCode === "200") {
        onSucess();
        setLoading(false);
        return;
      }
      swal("warning", response.data.message, "warning");
      setLoading(false);
    })
    .catch((error) => {
      error.clientMessage = "Can't create master";
      setLoading(false);
    });
};
export const createMaster = (
  masterForCreation,
  setLoading,
  onSucess,
  dispatch
) => {
  setLoading(true);
  return requestFromServer
    .createmaster(masterForCreation)
    .then((response) => {
      if (response.data.statusCode === "200") {
        console.log("values to be saved ",response.data.data.cM_Id,
          response.data.data.cU_Id,
          masterForCreation.name,
          masterForCreation.financialValuesIn)
         
        onSucess(
          response.data.data.cM_Id,
          response.data.data.cU_Id,
          masterForCreation.name,
          masterForCreation.financialValuesIn
        );
        console.log(" response.data.data.cM_Id", response.data.data.cM_Id)
        setLoading(false);
        return;
      }
      swal("Warning", response.data.message, "warning");
      setLoading(false);
    })
    .catch((error) => {
      error.clientMessage = "Can't create master";
      setLoading(false);
    });
};
export const postcompanyVsign = (state, setLoading, onSucess) => {
  setLoading(true);
  return requestFromServer
    .postCompanyVsign(state)
    .then((response) => {
      if (response.data.statusCode === "200") {
        onSucess();
        setLoading(false);
        return;
      }
      swal("warning", response.data.message, "warning");
      setLoading(false);
    })
    .catch((error) => {
      error.clientMessage = "Can't create master";
      swal("warning", catchOtherStatusErrors(error), "warning");
      setLoading(false);
    });
};

export const putcompanyVsign = (state, setLoading, onSucess) => {
  setLoading(true);
  return requestFromServer
    .putCompanyVsign(state)
    .then((response) => {
      if (response.data.statusCode === "200") {
        onSucess();
        setLoading(false);
        return;
      }
      swal("warning", response.data.message, "warning");
      setLoading(false);
    })
    .catch((error) => {
      error.clientMessage = "Can't create master";
      swal("warning", catchOtherStatusErrors(error), "warning");
      setLoading(false);
    });
};
export const updateCompanyCVs = (state, setLoading, onSucess, btnTitle) => {
  setLoading(true);
  return requestFromServer
    .updateCompanyCVs(state)
    .then((response) => {
      setLoading(false);
      if (response.data.statusCode === "200") {
        onSucess(btnTitle);
        return;
      }
      swal("warning", response.data.message, "warning");
      setLoading(false);
    })
    .catch((error) => {
      error.clientMessage = "Can't create master";
      swal("warning", catchOtherStatusErrors(error), "warning");
      setLoading(false);
    });
};
export const updateMaster = (master, setLoading, onHide) => {
  setLoading(true);
  return requestFromServer
    .updatemaster(master)
    .then((response) => {
      if (response.data.statusCode === "200") {
        //        setMasterLoading(true);
        onHide();
        setLoading(false);
        return;
      }
      swal("warning", response.data.message, "warning");
      setLoading(false);
    })
    .catch((error) => {
      swal("warning", catchOtherStatusErrors(error), "warning");
      error.clientMessage = "Can't create master";
      setLoading(false);
    });
};

export const GetPDGroupLOV = (CM_ID, VS_Id, setPDGroups, setLoading) => {
  setLoading(true);
  return requestFromServer
    .getPDgroupLOV(CM_ID, VS_Id)
    .then((res) => {
      setLoading(false);
      if (res.data.statusCode === "200") {
        setPDGroups(res.data.data);
      }
    })
    .catch((error) => {
      swal("warning", catchOtherStatusErrors(error), "warning");
      error.clientMessage = "Can't Get PDG";
      setLoading(false);
    });
};

export const GetCompanyPDS_PDLov = (
  PDGID,
  setCOmpanyPDS_PD,
  setLoading,
  CMID,
  CVSID,
  pdT_Id
) => {
  setLoading(true);

  return requestFromServer
    .getcompanymastertemplate(CMID, CVSID, PDGID)
    .then((response) => {
      if (response.data.statusCode === "200" && response.data.data.changeInTemplate === false) {
        console.log("response.data.data.changeInTemplate",response.data.data.changeInTemplate)
      // var v1=response.data.data.changeInTemplate
      console.log("In companytemplate master")
        setLoading(false);
        const companyData = JSON.parse(response.data.data.ds);
        const companyTemplateDetails = keyBy(response.data.data.companyTemplateDetails, 'pdM_Id');
        let companyDataWithUOM = [];
        companyData.map((companyDataItem, PDMIndex) => {
          let primaryDataMastersByGroup = [];
          companyDataItem.primaryDataMasters.map((primaryDataMastersItem, PDIndex) => {
            let newPDData = [];
            primaryDataMastersItem.pD_data.map((PDDataItem, index) => {
              newPDData.push({ ...PDDataItem, unit_Of_Measure: companyTemplateDetails[primaryDataMastersItem.pD_Id]['unit_Of_Measure'] });
            })
            primaryDataMastersByGroup.push({ ...primaryDataMastersItem, pD_data: newPDData })
          })
          companyDataWithUOM.push({ ...companyDataItem, primaryDataMasters: primaryDataMastersByGroup })
        });
        return setCOmpanyPDS_PD(companyDataWithUOM);
      } else {
        return requestFromServer
          .getcompanypdgs_pdlov( CMID,
            CVSID,PDGID, pdT_Id)
          .then((res) => {
            if (res.data.statusCode === "200"  ) {
              console.log("In pds lov")
              let a = res.data.data.map((d) => ({
                ...d,
                pdS_state: false,
                primaryDataMasters: d.primaryDataMasters.map((i) => {
                  return {
                    ...i,
                    pD_data: [
                      {
                        id: JSON.stringify(i.pD_Id),
                        label: i.pD_Name,
                        pd_Account_code: "",
                        unit_Of_Measure: i?.unit_Of_Measure || '',
                        pd_Alert_Criteria_From: 0,
                        pd_Alert_Value_From: 0,
                        pd_Alert_Criteria_To: 0,
                        pd_Alert_Value_To: 0,
                        childern: [],
                      },
                    ],
                  };
                }),
              }));
              setCOmpanyPDS_PD(a);
            }
            setLoading(false);
          })
          .catch((error) => {
            swal("warning", catchOtherStatusErrors(error), "warning");
            error.clientMessage = "Can't Get PDG";
            setLoading(false);
          });
      }
    })
    .catch((error) => {
      swal("warning", catchOtherStatusErrors(error), "warning");
      error.clientMessage = "Can't create Company Template";
      setLoading(false);
    });
};

export const postCompanyTemplate = (state, setLoading, onSucess, pro) => {
  setLoading(true);
  return requestFromServer
    .postcompanymastertemplate(state)
    .then((response) => {
      setLoading(false);
      if (response.data.statusCode === "200") {
        onSucess(pro);
        return;
      }
      swal("warning", response.data.message, "warning");
    })
    .catch((error) => {
      swal("warning", catchOtherStatusErrors(error), "warning");
      error.clientMessage = "Can't create Company Template";
      setLoading(false);
    });
};

export const fetchTargets = async (
  CVSID,
  setLoading,
  setTargets,
  CMID,
  vS_Id
) => {
  setLoading(true);
  return requestFromServer
    .fetchCompanyTargets(CMID, vS_Id)
    .then((res) => {
      if (res.data.statusCode === "200") {
        setLoading(false);
        setTargets(res.data.data);
      } else {
        return requestFromServer
          .fetchdefaultCompanyTargets(CMID, CVSID)
          .then((resp) => {
            setLoading(false);
            if (resp.data.statusCode === "200") {
              setTargets(resp.data.data);
            }
          });
      }
    })
    .catch((err) => {
      setLoading(false);
    });
};

export const GetDefaultCompanyTarget = async (
  CVSID,
  setLoading,
  setTargets,
  CMID,
  vS_Id
) => {
  setLoading(true);
  return requestFromServer
    .fetchdefaultCompanyTargets(CMID, vS_Id)
    .then((res) => {
      if (res.data.statusCode === "200") {
        setLoading(false);
        setTargets(res.data.data);
      } 
      // else {
      //   return requestFromServer
      //     .fetchdefaultCompanyTargets(CMID, CVSID)
      //     .then((resp) => {
      //       setLoading(false);
      //       if (resp.data.statusCode === "200") {
      //         setTargets(resp.data.data);
      //       }
      //     });
      // }
    })
    .catch((err) => {
      setLoading(false);
    });
};






export const updateCompanyTargets = (state, setLoading, onSucess) => {
  setLoading(true);
  return requestFromServer
    .updateCompanyTargets(state)
    .then((response) => {
      if (response.data.statusCode === "200") {
        onSucess();
        setLoading(false);
        return;
      }
      swal("warning", response.data.message, "warning");
      setLoading(false);
    })
    .catch((error) => {
      error.clientMessage = "Can't create master";
      setLoading(false);
    });
};

export const GetDefaultCompanyTolerence = (setLoading, setDefaultTolerence) => {
  setLoading(true);
  return requestFromServer
    .getdefaultcompanytolerence()
    .then((res) => {
      setLoading(false);
      if (res.data.statusCode === "200") {
        setDefaultTolerence(res.data.data.toleranceDetails);
      }
    })
    .catch((error) => {
      swal("warning", catchOtherStatusErrors(error), "warning");
      setLoading(false);
    });
};

export const GetPricePlanUtilized = async (id,CM_ID, setUtilized,setLoading) => {
  setLoading(true);
  return await requestFromServer
    .getPricePlanUtilized(id,CM_ID)
    .then((res) => {
      setLoading(false);
      if (res.data.statusCode === "200") {
        setUtilized(res.data.data);
      }
    })
    .catch((error) => {
      swal("warning", catchOtherStatusErrors(error), "warning");
      setLoading(false);
    });
};

export const GetCompanyTolerence = (id, setDefaultTolerence) => {
  console.log("sjfdnsdfsf",id)
  return requestFromServer
    .getcompanytolerence(id)
    .then((res) => {
      if (res.data.statusCode === "200") {
        setDefaultTolerence(res.data.data.companyTolerances);
      }
    })
    .catch((error) => {
      swal("warning", catchOtherStatusErrors(error), "warning");
    });
};

export const AddCompanyTolerence = (setSubmittLoading, stat, onSucess) => {
  setSubmittLoading(true);
  return requestFromServer
    .addcompanytolerence(stat)
    .then((res) => {
      if (res.data.statusCode === "200") {
        onSucess();
      } else {
        swal(res.data.message);
      }
    })
    .catch((error) => {
     
      error.clientMessage = "Can't create Tolerence";
      setSubmittLoading(false);
    });
};

export const BindCompany = (setSubmittLoading, stat, onSucess) => {
  setSubmittLoading(true);
  return requestFromServer
    .bindcompany(stat)
    .then((res) => {
      if (res.data.statusCode === "200") {
        onSucess();
      } else {
        swal(res.data.message);
      }
    })
    .catch((error) => {
      swal("warning", catchOtherStatusErrors(error), "warning");
      error.clientMessage = "Can't Bind Company";
      setSubmittLoading(false);
    });
};

export const FetchMenus = (onSucess) => {
  return requestFromServer
    .fetchmenus(9)
    .then((res) => {
      if (res.data.statusCode === "200") {
        onSucess(
          res.data.data.map((item) => ({ label: item.name, value: item.id }))
        );
      } else {
        swal(res.data.message);
      }
    })
    .catch((error) => {
      swal("warning", catchOtherStatusErrors(error), "warning");
      error.clientMessage = "Can't Bind Company";
    });
};

export const InviteUserCall = (state, setLoading, onSucess) => {
  console.log("state",state)
  setLoading(true);
  return requestFromServer
    .inviteuser(state)
    .then((res) => {
      setLoading(false);
      if (res.data.statusCode === "200") {
        onSucess();
      } else {
        swal(res.data.message);
      }
    })
    .catch((error) => {
      setLoading(false);
      swal("warning", catchOtherStatusErrors(error), "warning");
      error.clientMessage = "Can't Bind Company";
    });
};
