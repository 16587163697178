import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { ContentRoute } from "../../_metronic/layout";
const UserPersona = React.lazy(() => import("../auth-module/user-persona"));
const Welcome = React.lazy(() => import("../dashboard/welcome"));
const PricingPlan = React.lazy(() => import("../dashboard/pricing-plan"));
const ConductDiagnosis = React.lazy(() =>
  import("../dashboard/conduct-diagnosis")
);
const MemberDetails = React.lazy(() => import("../dashboard/member-details"));
const BillingDetail = React.lazy(() => import("../dashboard/billing-detail"));
const GatewayRender = React.lazy(() => import("../dashboard/gateway-detail"));
const TermsConditions = React.lazy(() =>
  import("../common/footer/Policies/terms-conditions")
);
const Feedback = React.lazy(() =>
  import("../common/footer/Policies/feedback")
);
const Confidentiality = React.lazy(() =>
  import("../common/footer/Policies/confidentiality")
);
const PrivacyPolicy = React.lazy(() =>
  import("../common/footer/Policies/privacy-policy")
);
const CompanyProfilePage = React.lazy(() =>
  import("../dashboard/company-profile/company-profile-page")
);
const SideBarOperationsTable = React.lazy(() =>
  import("../common/all-templates-tables/operations-table")
);
const PaidDiagnosisPage = React.lazy(() =>
  import("../dashboard/paid-diagnosis/paid-diagnosis-page")
);
const DiagnosisPeriodPage = React.lazy(() =>
  import("../dashboard/diagnosis-period/diagnosis-period")
);
const HelpandSupport = React.lazy(() =>
  import("../HelpandSupport/HelpandSupport")
);
const WelcomeMember = React.lazy(() => import("../dashboard/welcome-member"));

export default function BasePage() {
  const { isInvited } = useSelector((state) => state.auth.user);
  const { persona } = useSelector((state) => state.auth.user);
console.log("redirecttt")
  return (
    
      <Switch>
         {persona === "" ? (
          <Redirect exact from="/" to="/user-persona" />
        ) : (
          <Redirect exact from="/" to="/welcome" />
        )}
        <Route exact path="/user-persona" component={UserPersona} />
        <Route exact path="/welcome" component={Welcome} />
        <Route path="/pricing-plan" component={PricingPlan} />
        <Route exact path="/welcome-member" component={WelcomeMember} />
        <Route path="/helpandsupport" component={HelpandSupport} />
        <Route exact path="/company-diagnosis" render={(props) => <ConductDiagnosis {...props} key={'company-diagnosis'}/>} />
        <Route exact path="/company-dashboard" render={(props) => <ConductDiagnosis {...props} key={'company-dashboard'}/>} />
        <Route path="/member-details" component={MemberDetails} />
        <Route path="/company-profile" component={CompanyProfilePage} />
        <Route path="/diagnosis-period" component={DiagnosisPeriodPage} />
        <Route path="/paid-diagnosis" component={PaidDiagnosisPage} />
        <Route path="/payment" component={GatewayRender} />
        <Route exact path="/terms-conditions" component={TermsConditions} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/confedentiality-policy" component={Confidentiality} />
        <Route exact path="/feedback-user" component={Feedback} />

        <ContentRoute
          path="/welcome/company-profile/template/operations"
          component={SideBarOperationsTable}
        />
      </Switch>
    
  );
}
