import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";

export const PULL_DIAGNOSIS_RESULT = "PULL_DIAGNOSIS_RESULT";
export const CLEAR = "CLEAR";
export const BHMVSPD="BHMVSPD"

//action creators
export function pullDiagnoseResult(data) {
  return {
    type: PULL_DIAGNOSIS_RESULT,
    payload: data,
  };
}

export function ClearDiagnoseResult() {
  return { type: CLEAR };
}
let diagnosis = {
  Vsigns: [],
  BusinessResults:[],
  BHM:[],

};

export const DiagnoseResultReducer = persistReducer(
  { storage, key: "v711-diagnose-result" },
  (state = diagnosis, action) => {
    switch (action.type) {
      case PULL_DIAGNOSIS_RESULT:
        return {
          ...state,
          Vsigns: action.payload.Vsigns,
          BusinessResults:action.payload.BusinessResults
        };
      case CLEAR:
        return diagnosis;
        case BHMVSPD:
          return{
            ...state,
            BHM:action.payload
          }
         
      default:
        return state;
    }
  }
);
