/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useRoutesContext } from "../../../../../app/routes/RoutesContext";

export function HelpToggler() {
  const RoutesContext = useRoutesContext();

  return (
    <>
      <OverlayTrigger
        placement="bottom"
        overlay={<Tooltip>Help and Support</Tooltip>}
      >
        <div
          className="topbar-item"
          onClick={() => RoutesContext.setHelpAside(true)}
        >
          <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2">
            <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
              <i class="fa fa-question-circle" aria-hidden="true"></i>
            </span>
          </div>
        </div>
      </OverlayTrigger>
    </>
  );
}
