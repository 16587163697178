import React from "react";
import { Switch, Redirect, useHistory, useLocation ,Route} from "react-router-dom";
import { ContentRoute } from "../../_metronic/layout";
import { assets } from "../assets";
import { Link } from "react-router-dom";
import "../auth-module/auth-module.styles.css";
const Logout = React.lazy(() => import("../auth-module/logout"));
const Login = React.lazy(() => import("../auth-module/login"));
const SignUp = React.lazy(() => import("../auth-module/signup"));

const TermsConditions = React.lazy(() =>
  import("../auth-module/terms-conditions")
);
const ChangePassword = React.lazy(() => import("../auth-module/change-password"))
const EmailVerification = React.lazy(() =>
  import("../auth-module/email-verification")
);
const ForgotPassword = React.lazy(() =>
  import("../auth-module/forgot-password")
);
const CheckMail = React.lazy(() => import("../auth-module/check-mail"));

export default function AuthPage() {
  const location = useLocation();
  console.log("redirecttt ayth")
  return (
    <div className="Auth-module-bg">
      <div className={`Auth-module-card ${location.pathname === '/auth/signup' ? 'Signup' : 'Signin'}`}>
        <div className="row">
          {location.pathname !== '/auth/terms-conditions' &&
            <div
              className="col-lg-6 col-12 "
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >

              <div className="Welcome-card">
                <h2>Welcome to</h2>
                <img src={assets.Logo} alt="logo" />
                <p>
                  Diagnose your Business Health over 7 Vital Signs & 35 Health
                  Markers and empower your teams to success
                </p>
                {/* <Link to="/auth/signin">Sign In Page</Link> //commented for cosmetic issue #155 */}
              </div>
            </div>}
          <Switch>
            <ContentRoute path="/auth/login" component={Login} />
            <ContentRoute
              path="/auth/changepassword"
              component={ChangePassword}
            />
            <ContentRoute
              path="/auth/forgot-password"
              component={ForgotPassword}
            />
            <ContentRoute path="/auth/signup" component={SignUp} />
            <ContentRoute exact path="/auth/terms-conditions" component={TermsConditions} />
            <ContentRoute
              path="/auth/email-verification"
              component={EmailVerification} />
            <ContentRoute path="/auth/check-mail" component={CheckMail} />
            <ContentRoute path="/auth/logout" component={Logout} />
            <Redirect from="/auth" exact={true} to="/auth/login" />
            <Redirect to="/auth/login" />
          </Switch>
        </div>
      </div>
    </div>
  );
}
