/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */

import { Divider } from "@material-ui/core";
import React from "react";
import SVG from "react-inlinesvg";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive, toAbsoluteUrl } from "../../../../_helpers";

export function PDAsideMenuList({ layoutProps }) {
  const permVsign = useSelector((state) => state.PermissionReducer.vsigns);
  const location = useLocation();
  const { isInvited } = useSelector((state) => state.auth.user);
  const DiagnoseVS = useSelector(
    (state) => state.DiagnoseReducer.vSignsDiagnosis
  );
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      {/* <ul
        className={`menu-nav data-auto-scroll="true" ${layoutProps.ulClasses}`}
      >
        {DiagnoseVS &&
          DiagnoseVS.length > 0 &&
          DiagnoseVS.map((item) => (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                `/paid-diagnosis/primary-data/view/${item.code.toLowerCase()}`,
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink
                className="menu-link menu-toggle"
                to={`/paid-diagnosis/primary-data/view/${item.code.toLowerCase()}`}
              >
                <span className="svg-icon menu-icon">
                  <img
                    src={item.icon}
                    style={{ width: 25, height: 25, objectFit: "contain" }}
                    alt=""
                  />
                </span>
                <span className="menu-text">{item.vS_Name}</span>
              </NavLink>
            </li>
          ))}
      </ul> */}
        <ul
        className={`menu-nav data-auto-scroll="true" ${layoutProps.ulClasses}`}
      >
        {DiagnoseVS &&
          DiagnoseVS.length > 0 &&
          DiagnoseVS.filter((i)=>i.vS_Id!=88).map((item) => (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                `/paid-diagnosis/primary-data/view/${item.code.toLowerCase()}`,
                true
              )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            {isInvited ?
              permVsign.length > 0 && permVsign.filter(val => val.vS_Id == item.vS_Id).map((val) =>
              (
                <NavLink
                className="menu-link menu-toggle"
                to={`/paid-diagnosis/primary-data/view/${item.code.toLowerCase()}`}
                >
                  <span className="svg-icon menu-icon">
                    <img
                      src={item.icon}
                      style={{ width: 25, height: 25, objectFit: "contain" }}
                      alt=""
                    />
                  </span>
                  <span className="menu-text">{val.vS_Name}</span>
                </NavLink>
              )
              )
              :
              <NavLink
              className="menu-link menu-toggle"
              to={`/paid-diagnosis/primary-data/view/${item.code.toLowerCase()}`}
              >
                <span className="svg-icon menu-icon">
                  <img
                    src={item.icon}
                    style={{ width: 25, height: 25, objectFit: "contain" }}
                    alt=""
                  />
                </span>
                <span className="menu-text">{item.vS_Name}</span>
              </NavLink>}
          </li>
          ))}
      </ul>
      {/* <Divider />
      <ul
        className={`menu-nav data-auto-scroll="true" ${layoutProps.ulClasses}`}
      >
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "#",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="#">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Sidebar/whats-new.svg")}
              />
            </span>
            <span className="menu-text">What's New</span>
          </NavLink>
        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "#",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="#">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Sidebar/help.svg")} />
            </span>
            <span className="menu-text">Help</span>
          </NavLink>
        </li>
      </ul> */}

      {/* end::Menu Nav */}
    </>
  );
}
