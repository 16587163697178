import Logo from "./logo.png";
import GoogleLogo from "./google-icon.png";
import LinkedinLogo from "./linkedin-icon.png";
import ImagegMailbox from "./img-mailbox.png";
import AsqueyLogo from "./asquey-logo.png";
import PersonaCard1 from "./persona-card-1.png";
import PersonaCard2 from "./persona-card-2.png";
import PersonaCard3 from "./persona-card-3.png";
import PersonaCard4 from "./persona-card-4.png";
import WatchVideo from "./watch-video.png";
import WatchVideo1 from "./ipad.svg";
import Analysis from "./analysis.png";
import Dashboard from "./dashboard.png";
import Company from "./company.png";
import Record from "./record-new.png"; //cosmetic change #159
import IdCard from "./id-card-new.png"; //cosmetic change #159
import Seed from "./seed.png";
import Leaf from "./leaf.png";
import Flower from "./flower.png";
import Fruit from "./fruit.png";
import Tree from "./tree.png";
import FinanceLogo from "./finance.png";
import GovtLogo from "./govt.png";
import MarketingLogo from "./marketing.png";
import OperationsLogo from "./operations.png";
import WhatsNewLogo from "./whats-new.png";
import HumanCapitalLogo from "./human-capital.png";
import HelpLogo from "./help.png";
import ItBiLogo from "./it-bi.png";
import InnovationBusinessLogo from "./innovation-business.png";
import thermometer from "./thermometer.png";
import LogoExcel from "./logo-excel.png";
import LogoOracle from "./logo-oracle.png";
import LogoQB from "./logo-qb.png";
import LogoSap from "./logo-sap.png";
import Yearly from "./yearly.png";
import Monthly from "./monthly.png";
import Quarterly from "./quarterly.png";
import Utilized from "./utilized.png";
import PricePlan from "./price.png";
import Invite from "./invite.png";
import Paypal from "./paypal.png";
import MasterCard from "./mastercard.png"
import Visa from "./visa.png";
import HeartBeat from './loading-beat.gif';
import Whatsapp from './whatsapp.png';
export const assets = {
  Paypal:Paypal,
  MasterCard:MasterCard,
  Visa:Visa,
  Whatsapp:Whatsapp,
  Logo: Logo,
  GoogleLogo: GoogleLogo,
  LinkedinLogo: LinkedinLogo,
  ImagegMailbox: ImagegMailbox,
  AsqueyLogo: AsqueyLogo,
  PersonaCard1: PersonaCard1,
  PersonaCard2: PersonaCard2,
  PersonaCard3: PersonaCard3,
  PersonaCard4: PersonaCard4,
  Record: Record,
  Company: Company,
  Dashboard: Dashboard,
  Analysis: Analysis,
  IdCard: IdCard,
  WatchVideo: WatchVideo,
  WatchVideo1: WatchVideo1,
  Seed: Seed,
  Leaf: Leaf,
  Flower: Flower,
  Fruit: Fruit,
  Tree: Tree,
  FinanceLogo: FinanceLogo,
  GovtLogo: GovtLogo,
  MarketingLogo: MarketingLogo,
  OperationsLogo: OperationsLogo,
  WhatsNewLogo: WhatsNewLogo,
  HumanCapitalLogo: HumanCapitalLogo,
  HelpLogo: HelpLogo,
  ItBiLogo: ItBiLogo,
  InnovationBusinessLogo: InnovationBusinessLogo,
  Thermometer: thermometer,
  LogoExcel: LogoExcel,
  LogoOracle: LogoOracle,
  LogoQB: LogoQB,
  LogoSap: LogoSap,
  Yearly: Yearly,
  Monthly: Monthly,
  Quarterly: Quarterly,
  Utilized:Utilized,
  PricePlan:PricePlan,
  Invite:Invite,
  HeartBeat:HeartBeat
};
