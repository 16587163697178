import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import AuthPage from "./AuthPage";
import BasePage from "./BasePage";
import { RoutesProvider } from "./RoutesContext";

export default function Routes(props) {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <>
          {/* <Redirect to="/auth/login" /> */}
          <Route>
            <AuthPage />
          </Route>
        </>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}
      
       {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        
        <RoutesProvider>
        <BasePage />
        </RoutesProvider>
      )}
    </Switch>
  );
}
