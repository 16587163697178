import { HelpOutline } from "@material-ui/icons";
import React from "react";
import PageHelpTipAside from "./PageHelpTipAside";
// import {LightbulbIcon} from '@material-ui/icons';
export default function FAQDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const { menuData } = props;
  const handleClick = (title) => {
    setTitle(title);
    setOpen(!open);
    console.log("title",title)
  };
  console.log("title",title)
//   console.log("menuData",menuData)
  return (
    <div>
      <button
        className="btn btn-light btn-sm font-weight-bold mr-2"
        onClick={() => handleClick("Page Tips")}
      >
        <span className="text-muted font-weight-bold mr-2" id="kt_dashboard_daterangepicker_title">
          <i class="far fa-lightbulb"></i>
        </span>
        <span
          className="text-primary font-weight-bold"
          id="kt_dashboard_daterangepicker_date"
        >
          Page Tips
        </span>
      </button>
      {/* <button
        className="btn btn-light btn-sm font-weight-bold ml-2"
        onClick={() => handleClick("Page Help")}
      >
        <span
          className="text-muted font-weight-bold mr-2"
          id="kt_dashboard_daterangepicker_title"
        >
          <HelpOutline />
        </span>
      </button> */}
      <PageHelpTipAside
        open={open}
        setOpen={setOpen}
        menuData={menuData}
        title={title}
        {...props}
      />
    </div>
  );
}
