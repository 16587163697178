import macaddress from "macaddress";
import React, { useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import Routes from "./routes/index";
import { SnackBarUIProvider } from "./SnackBarContext";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function App({ store, persistor, basename }) {
  const [open, setOpen] = useState(false);
  const [origin, setOrigin] = useState({
    vertical: "top",
    horizontal: "center",
  });
  const [message, setMessage] = useState("");
  macaddress.one(function(err, mac) {
    console.log("Mac address for this host: %s", mac);
  });
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(!open);
  };
  const SnackBarUIEvents = {
    position: 6000,
    open: open,
    setOpen: setOpen,
    handleClose: handleClose,
    duration: 6000,
    anchorOrigin: origin,
    message: message,
    setMessage: setMessage,
  };

  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              <SnackBarUIProvider SnackBarEvents={SnackBarUIEvents}>
                {/* Render routes with provided `Layout`. */}
                <Routes />
                <Snackbar
                  open={open}
                  anchorOrigin={origin}
                  autoHideDuration={3000}
                  onClose={handleClose}
                >
                  <Alert onClose={handleClose} severity="success">
                    {message}
                  </Alert>
                </Snackbar>
              </SnackBarUIProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
