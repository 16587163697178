import { useField, useFormikContext } from "formik";
import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function DatePickerField({ ...props }) {
  let fiscal=props.fiscal
  console.log("fiscal test",fiscal)
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);
  const isOwner = useSelector((state) => state.CompanyStepsReducer.isOwner);
  const getFormattedDate = (value) => {
    let date = new Date(value).toString();
    date = date.split(" ");
    return value != "" ? date[2] + "/" + date[1] : ""; //test changed
  };
  return (
    <>
      {props.label && <label>{props.label}</label>}
      <DatePicker
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        style={{ width: "100%" }}
        {...field}
        {...props}
        // disabled={isOwner ? !isOwner : false}
        value={getFormattedDate(field.value)}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
      />
      {errors[field.name] && touched[field.name] ? (
        <div
          style={{ color: "#F693C7" }}
          className="invalid-datepicker-feedback"
        >
          {errors[field.name].toString()}
        </div>
      ) : (
        <div className="feedback">
          {/* <b>{props.label}</b> in 'dd/mm/yyyy' format */}
          {/* <p style={{ fontSize: "10px" }}>{fiscal?"Date in 'mmm/dd' format":"Date in 'dd/mm/yyyy' format"}</p> */}
        </div>
      )}
    </>
  );
}
