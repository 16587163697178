import { createMuiTheme, IconButton, ThemeProvider } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { useSelector ,useDispatch} from "react-redux";
import { helpSupport } from "../../../redux/companyStepsReducer";
import SVG from "react-inlinesvg";
import {useHistory, useLocation} from 'react-router-dom'
import { useRoutesContext } from "../../../app/routes/RoutesContext";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import FAQs from "./faq-aside/FAQs";
import Help from "./faq-aside/Help";
import { TopHeaderComponent } from "../../common/aside/TopHeaderComponent";
const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: "30%",
    marginTop: theme.spacing(9),
    height: "85%",
  },
  description: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fffff",
    borderBottom: "1px solid #f3f3f3",
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fffff",
    borderBottom: "1px solid #f3f3f3",
  },
  container: {
    width: "80%",
    border: "solid",
    borderRadius: "7px",
    marginTop: "10px",
    borderWidth: "thin",
    display: "flex",
  }
}));
const theme = createMuiTheme({
  ".MuiPaper-elevation1": {
    boxShadow: "0",
  },
});
export default function PageHelpTipAside(props) {
  var val=true
  const classes = useStyles();
  const dispatch=useDispatch()
  const RoutesContext = useRoutesContext();
  console.log("RoutesContext",RoutesContext)
  const status = useSelector((state) => state.CompanyStepsReducer.status);
  console.log("status",status)
  const { open, setOpen, menuData, title } = props;
  const history=useHistory();
  const location = useLocation();
  console.log("in top headerr")
console.log("menudata",menuData)

const onClose = () => {
  dispatch(
    helpSupport({
      status: false,
    }))
   
  setOpen(false);
  //history.push(location.pathname);
}

  return (
    <div>
      <React.Fragment>
        <Drawer anchor="right" classes={{paper: classes.drawerPaper,}} open={status||open}>
          <div className={`${classes.header} p-2`}>
            <div className="d-flex flex-row">
              <span className="svg-icon menu-icon">
                {
                  title === "Page Help" ?
                  <img alt="img" src="https://img.icons8.com/office/16/000000/help.png"/>
                   
                    : <SVG src={toAbsoluteUrl("/media/svg/shapes/bulb.svg")} />
                }
              </span>
              <span class="font-medium align-middle ml-2">{status?'Help and Support':title}</span>
            </div>
            <IconButton
              color="primary"
              component="span"
              onClick={() => onClose()}
            >
             <CloseIcon />
            </IconButton>
          </div>
         
        
          {status ?
           (
            <>
              <TopHeaderComponent {...props} />
            </>
          ) : (
            <div>
              <div>
                <h5 class="text-dark font-weight-bold my-2 ml-2 mr-2">
                  {menuData?.name ?? "Name"}
                  {console.log("innnn page",title)}
                </h5>
                <div className={`${classes.description} p-2`}>
                  <div class="comment-date my-2 ml-5 mr-5 font-small">
                    {menuData?.description}
                    {console.log("descrip[tionnn]")}
                  </div>
                </div>
              </div>
              
              <ThemeProvider theme={theme}>
              {menuData && (title === "Page Help" ? (
                <Help menuData={menuData} classes={classes} />
              ) : (
                <FAQs menuData={menuData} classes={classes} />
              ))}
              </ThemeProvider>
            </div>
          )}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
