//import axios from "axios";

import AppAxios, { Headers } from "../../../axios";

export const MASTER_URL = "/CompanyMaster";
export const BusinessType_URL = "/BusinessType";
export const City_URL = "/LovServices/GetCities";

//Lovs
export const RegionCountry_URL="/LovServices/GetCountriesLov"
export const VSigns_URL = "/Vsign";
export const IndustryType_URL = "/LovServices/GetIndustryTypeLov";
export const IndustryCluster_URL =
  "/LovServices/GetIndustryClusterMaster_WRTO_BT";
export const RegionMaster_URL = "/LovServices/GetRegionsLov";
export const Country_URL = "/LovServices/GetCountriesLov";
export const CompanyAge_URL = "/LovServices/GetCompanyAgeLov";
export const CompanySize_URL = "/LovServices/GetCompanySizeLov";
export const CompanyVsign = "/CompanyVSign";
export const GetCompanyHMById = "/LovServices/GetCompanyHMById";
export const HealthMarkerWRVsign = "/LovServices/GetCompanyHMLovClient";
export const GETDEFAULTCONNECTIONSTRENGTH = "/LovServices/GetCSByVS";
export const LOVSERVICES = "/LovServices";
export const GETCMCONNECTIONSTRENGTH = "/CompanyVSignCS/GetCompanyVSignCSById";
export const DEFAULTINDUSTRYClUSTERCS ="/LovServices/GetDefaultIndustryClusterCS"
export const ADDCOMPANYCONNECTEDVSIGN = "/CompanyVSignCS";
export const ADDCOMPANYHM = "/CompanyHM";
export const CompanyTargets = "/LovServices/GetCompanyTargetLov";
// export const UpdateCompanyTargets_URL = "/LovServices/GetDefaultCompanyTargetLov";
export const AddCompanyTargets_URL = "/CompanyTarget";
export const UserBindingCompany_URL = "/LovServices/GetUserAllBindingCompany";
export const UserCompany_URL = "/LovServices/GetUserCompanyLov";
export const DiagnoseMaster = "/DiagnoseMaster";
export const DiagnosePeriod = "/DiagnosePeriod";
export const GETCOMPANY_USER_PERMISSION =
  "/LovServices/GetCompanyUserPermission";
// CREATE =>  POST: add a new master to the server
export function createmaster(master) {
  return AppAxios.post(MASTER_URL, master, { headers: Headers() });
}

export function postCompanyVsign(state) {
  return AppAxios.post(CompanyVsign, state, { headers: Headers() });
}
export function addCompanyHm(state) {
  return AppAxios.post(ADDCOMPANYHM, state, { headers: Headers() });
}
export function updateCompanyHm(state) {
  return AppAxios.put(ADDCOMPANYHM, state, { headers: Headers() });
}
export function updateCompanyTargets(state) {
  return AppAxios.put(AddCompanyTargets_URL, state, { headers: Headers() });
}
export function putCompanyVsign(state) {
  return AppAxios.put(CompanyVsign, state, { headers: Headers() });
}
export function fetchCompanyHM(DM_Id, vS_Id) {
  return AppAxios.get(GetCompanyHMById + `?CM_Id=${DM_Id}&CVS_Id=${vS_Id}`, {
    headers: Headers(),
  });
}
export function fetchCompanyUserPermission(CM_ID) {
  return AppAxios.get(GETCOMPANY_USER_PERMISSION + `?CM_Id=${CM_ID}`, {
    headers: Headers(),
  });
}

// READ
export function getAllmasters() {
  return AppAxios.get(MASTER_URL, { headers: Headers() });
}
export function getUserCompany(title,cM_Id) {
  let URL = title == "invite" ? UserCompany_URL+`?CM_Id=${cM_Id}` : UserBindingCompany_URL;
  return AppAxios.get(URL, { headers: Headers() });
}
export function getCompanyUser() {
  return AppAxios.get("CompanyUser", { headers: Headers() });
}
export function fetchVSigns() {
  return AppAxios.get(VSigns_URL, { headers: Headers() });
}
export function fetchIndustryType() {
  return AppAxios.get(IndustryType_URL, { headers: Headers() });
}
export function fetchIndustryCluster(id) {
  return AppAxios.get(IndustryCluster_URL + `?BT_ID=${id}`, {
    headers: Headers(),
  });
}
export function fetchHealthMarkerWRVsign(cM_Id, vS_Id) {
  return AppAxios.get(HealthMarkerWRVsign + `?CM_Id=${cM_Id}&VS_Id=${vS_Id}`, {
    headers: Headers(),
  });
}
export function fetchVSignsByICID(CM_ID) {
  return AppAxios.get(
    DEFAULTINDUSTRYClUSTERCS + `?CM_ID=${CM_ID}`,
    {
      headers: Headers(),
    }
  );
}

export function fetchCVSignsByDMID(DM_Id) {
  console.log("dmmm",DM_Id)
  return AppAxios.get(GETCMCONNECTIONSTRENGTH, {
    headers: Headers(DM_Id),
  });
}

export function fetchCompanyVSignsByDMID(DM_Id) {
  return AppAxios.get(CompanyVsign + "/GetCompanyVSignById", {
    headers: Headers(DM_Id),
  });
}

export function fetchCVSbyCompany(CM_Id) {
  return AppAxios.get(`${LOVSERVICES}/GetCVSByCompany?CM_Id=${CM_Id}`, {
    headers: Headers(),
  });
}

export function fetchInvitedVsign(CM_Id) {
  console.log("cmid",CM_Id)
  return AppAxios.get(`/LovServices/GetInvitedVsign?CM_Id=${CM_Id}`, {
    headers: Headers(),
  });
}

export function postDiagnoseMaster(data) {
  return AppAxios.post(DiagnoseMaster, data, { headers: Headers() });
}

export function putDiagnoseMaster(data) {
  return AppAxios.put(DiagnoseMaster, data, { headers: Headers() });
}

export function postDiagnosePeriod(data) {
  return AppAxios.post(DiagnosePeriod, data, { headers: Headers() });
}
export function putDiagnosePeriod(data) {
  return AppAxios.put(DiagnosePeriod, data, { headers: Headers() });
}

export function postAllDiagnosePeriod(data, companyMasterId, periodMasterId) {
  return AppAxios.post(`${DiagnosePeriod}/AllDiagnosePeriodsFromCurrentPeriod/${companyMasterId}/${periodMasterId}`, data, { headers: Headers() });
}

export function postCompanyCVs(state) {
  return AppAxios.post(ADDCOMPANYCONNECTEDVSIGN, state, { headers: Headers() });
}

export function updateCompanyCVs(state) {
  return AppAxios.put(ADDCOMPANYCONNECTEDVSIGN, state, { headers: Headers() });
}

export function fetchBusinessType() {
  return AppAxios.get(BusinessType_URL, { headers: Headers() });
}
export function fetchCities(c_id) {
  return AppAxios.get(`${City_URL}?CNT_Id=${c_id}`, { headers: Headers() });
}
export function fetchCountry() {
  return AppAxios.get(Country_URL, { headers: Headers() });
}
export function fetchRegionCountry() {
  return AppAxios.get(RegionCountry_URL, { headers: Headers() });
}
export function fetchCompanyAge() {
  return AppAxios.get(CompanyAge_URL, { headers: Headers() });
}
export function fetchCompanySize() {
  return AppAxios.get(CompanySize_URL, { headers: Headers() });
}
export function fetchRegionMaster() {
  return AppAxios.get(RegionMaster_URL, { headers: Headers() });
}

export function getmasterById(masterId) {
  return AppAxios.get(`${MASTER_URL}/GetMemberMasterById`, {
    headers: Headers(masterId),
  });
}

export function getCompanyMasterById(masterId) {
  return AppAxios.get(`${MASTER_URL}/GetCompanyMasterById`, {
    headers: Headers(masterId),
  });
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findmasters(queryParams) {
  if (queryParams) {
    return AppAxios.get(`${MASTER_URL}/GetMemberMasterById`, {
      headers: Headers(queryParams),
    });
  } else {
    return AppAxios.get(MASTER_URL, { headers: Headers() });
  }
  //return AppAxios.post(`${MASTER_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the master on the server
export function updatemaster(master) {
  return AppAxios.put(`${MASTER_URL}`, master, { headers: Headers() });
}

// UPDATE Status
export function updateStatusFormasters(ids, status) {
  return AppAxios.post(`${MASTER_URL}/updateStatusFormasters`, {
    ids,
    status,
  });
}

// DELETE => delete the master from the server
export function deletemaster(masterId) {
  return AppAxios.delete(`${MASTER_URL}`, { headers: Headers(masterId) });
}

// DELETE masters by ids
export function deletemasters(ids) {
  return AppAxios.post(`${MASTER_URL}/deletemasters`, { ids });
}

export function getPDgroupLOV(CM_Id, VS_Id) {
  return AppAxios.get(
    `${LOVSERVICES}` + "/GetPDGroupLov" + `?CM_Id=${CM_Id}&VS_Id=${VS_Id}`,
    {
      headers: Headers(),
    }
  );
}

export function getcompanypdgs_pdlov(CMID,CVSID,PDG_Id, pdT_Id) {
  return AppAxios.get(
    `${LOVSERVICES}/GetCompanyPDS_PDLov?PDG_Id=${PDG_Id}&PDT_Id=${pdT_Id}&CVS_Id=${CVSID}&CM_Id=${CMID}`,
    {
      headers: Headers(),
    }
  );
}

export function postcompanymastertemplate(state) {
  return AppAxios.post("/CompanyTemplateMaster", state, {
    headers: Headers(),
  });
}

export function getcompanymastertemplate(dmid, vsid, PDG_Id) {
  return AppAxios.get(
    `/LovServices/GetCompanyTemplateMasterLov?CM_ID=${dmid}&CVS_Id=${vsid}&PGD_Id=${PDG_Id}`,
    {
      headers: Headers(),
    }
  );
}

export function fetchCompanyTargets(CM_Id, vS_Id) {
  return AppAxios.get(CompanyTargets + `?CM_Id=${CM_Id}&VS_Id=${vS_Id}`, {
    headers: Headers(),
  });
}

export function fetchdefaultCompanyTargets(CM_Id, vS_Id) {
  return AppAxios.get(
    "/LovServices/GetDefaultCompanyTarget" +
      `?CM_Id=${CM_Id}&VS_Id=${vS_Id}`,
    {
      headers: Headers(),
    }
  );
}

export function getdefaultcompanytolerence() {
  return AppAxios.get(`${LOVSERVICES}` + "/GetDefaultCompanyTolerance", {
    headers: Headers(),
  });
}

export function getcompanytolerence(id) {
  return AppAxios.get("/CompanyTolerance/GetCompanyToleranceById", {
    headers: Headers(id),
  });
}

export function addcompanytolerence(state) {
  return AppAxios.post("/CompanyTolerance", state, {
    headers: Headers(),
  });
}

export function bindcompany({ CMID, PPID }) {
  return AppAxios.get(
    `/LovServices/BindPricePlanCompany?CM_Id=${CMID}&PP_Id=${PPID}`,
    {
      headers: Headers(),
    }
  );
}

export function fetchmenus(MCID) {
  return AppAxios.get(`/LovServices/GetMenuCategoryMenu?MC_Id=${MCID}`, {
    headers: Headers(),
  });
}

export function inviteuser(state) {
  return AppAxios.post(`/companyuser`, state, {
    headers: Headers(),
  });
}


export function getPricePlanUtilized(id,cmid) {
  return AppAxios.get(
    `/LovServices/GetPricePlanUtilization?CM_Id=${cmid}&PP_Id=${id}`,
    {
      headers: Headers(),
    }
  );
}