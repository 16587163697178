import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";

export const ADD_ONLY_CMID = "ADD_ONLY_CMID";
export const PRICE_PLAN = "PRICE_PLAN";
export const ADD_SELECTEDVSIGN_DATA = "ADD_SELECTEDVSIGN_DATA";
export const ADD_CMID = "ADD_CMID";
export const CLEAR = "CLEAR";
export const HELPNSUPPORT = "HELPNSUPPORT";
export const PP = "PP";
export const SET_VSIGN_CODE="SET_VSIGN_CODE"
export const SET_ACTIVE_STEP = "SET_ACTIVE_STEP";

//action creators
export function AddSelectedVSign(data) {
  return {
    type: ADD_SELECTEDVSIGN_DATA,
    payload: data,
  };
}
export function AddCMID(data) {
  return {
    type: ADD_CMID,
    payload: data,
  };
}
export function PPID(data) {
  return {
    type: PP,
    payload: data,
  };
}
export function AddonlyCMID(data) {
  return {
    type: ADD_ONLY_CMID,
    payload: data,
  };
}
export function Priceplan(data) {
  return {
    type: PRICE_PLAN,
    payload: data,
  };
}
export function helpSupport(data) {
  return {
    type: HELPNSUPPORT,
    payload: data,
  };
}
// export function ppid(data) {
//   return {
//     type: PPID,
//     payload: data,
//   };
// }
export function ClearCompanyStepsDetail() {
  return { type: CLEAR };
}
export function setSelectedVsignCode(data){
  return{
    type:SET_VSIGN_CODE,
    payload: data,
  }
}
export function setCompanyProfileActiveStep(activeStep) {
  return {
    type: SET_ACTIVE_STEP,
    payload: activeStep,
  };
}
let CompanySteps = {
  selectedVsign: [],
  setActiveStep: {},
  cM_Id: null,
  cU_Id: null,
  name: null,
  isOwner: true,
  fiscal: null,
  financialValuesIn: null,
  bundleDiscount: null,
  promoOffer: null,
  salesTax: null,
  serviceTax: null,
  vatTax: null,
  additionalDiscount: null,
  address: null,
  status: null,
  ppid:null,
  vsignCode:'vf'

};
export const CompanyStepsReducer = persistReducer(
  { storage, key: "v711-company-steps" },
  (state = CompanySteps, action) => {
    switch (action.type) {
      case ADD_SELECTEDVSIGN_DATA:
        return {
          ...state,
          selectedVsign: action.payload.vSignDiag,
        };
      case SET_VSIGN_CODE:
        return{
          ...state,
          vsignCode:action.payload
        }
      case SET_ACTIVE_STEP: {
        return {
          ...state,
          setActiveStep: { ...state.setActiveStep, ...action.payload },
        };
      }
      case ADD_CMID:
        return {
          ...state,
          cM_Id: action.payload.Id,
          cU_Id: action.payload.cU_Id,
          isOwner: action.payload.isOwner,
          name: action.payload.name,
          fiscal: action.payload.fiscal,
          financialValuesIn: action.payload.financialValuesIn,
          designation: action.payload.designation,
          // ppid:action.payload.ppid
        };
        case PP:
          return {
            ...state,
            ppid:action.payload.ppid
          };
      case ADD_ONLY_CMID:
        return {
          ...state,
          cM_Id: action.payload.cM_Id,
          cU_Id: action.payload.cU_Id,
          name: action.payload.cM_Name,
        };
      case PRICE_PLAN:
        return {
          ...state,
          bundleDiscount: action.payload.bundleDiscount,
          promoOffer: action.payload.promoOffer,
          salesTax: action.payload.salesTax,
          serviceTax: action.payload.serviceTax,
          vatTax: action.payload.vatTax,
          additionalDiscount: action.payload.additionalDiscount,
          address: action.payload.address,
        };
      case HELPNSUPPORT:
        return {
          ...state,
          status: action.payload.status
        };
        // case PPID:
        //   return {
        //     ...state,
        //     status: action.payload.ppid
        //   };
      case CLEAR:
        return CompanySteps;
      default:
        return state;
    }
  }
);
