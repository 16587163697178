 import axios from "axios";
 import store from "./redux/store";
 const AppAxios = axios.create({
            baseURL: process.env.REACT_APP_END_POINT,
            // baseURL:"https://demo.syslinknetwork.com:126/api/v1",
          // //   // rteredxz
          //baseURL: process.env.REACT_APP_LOCAL_END_POINT,

});
export const NODE_BACKEND_URL = process.env.REACT_APP_NODE_URL;
export const Headers = (_id) => {
  const { authToken } = store.getState().auth;
  if (!_id) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: authToken,
    };
  }
  return {
    _id: _id,
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: authToken,
  };
};
export default AppAxios;
