import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";

export const ADD_DIAGNOSIS_DATA = "ADD_DIAGNOSIS_DATA";
export const NEXT_DRAWER_STATE = "NEXT_DRAWER_STATE";
export const SET_VSIGN = "SET_VSIGN";
export const UPDATE_DMID = "UPDATE_DMID";
export const UPDATE_QUARTER = "UPDATE_QUARTER";
export const CLEAR = "CLEAR";
export const ADD_TOLERENCE_RANGE = "ADD_TOLERENCE_RANGE";
export const GET_PRIMARYVIEW_DATA='GET_PRIMARYVIEW_DATA';
export const ADP_ID='ADP_ID';

//action creators
export function addDiagVsignData(data) {
  return {
    type: ADD_DIAGNOSIS_DATA,
    payload: data,
  };
}

export function nextDrawerState(data) {
  return {
    type: NEXT_DRAWER_STATE,
    payload: data,
  };
}

export function setSelectedVSign(data) {
  return {
    type: SET_VSIGN,
    payload: data,
  };
}

export function getPrimaryViewData(data) {
  return {
    type: GET_PRIMARYVIEW_DATA,
    payload: data,
  };
}
export function updateDMID(id) {
  return {
    type: UPDATE_DMID,
    payload: id,
  };
}
export function updateADP_ID(id) {
  return {
    type: ADP_ID,
    payload: id,
  };
}

export function updateTolerenceRange(data) {
  return {
    type: ADD_TOLERENCE_RANGE,
    payload: data,
  };
}

export function updateQuarter(data) {
  return {
    type: UPDATE_QUARTER,
    payload: data,
  };
}
export function ClearDiagnoseDetail() {
  return { type: CLEAR };
}
let diagnosis = {
  range: [],
  vSignsDiagnosis: [],
  nextDrawerState: "",
  selectedVSign: {},
  dM_Id: "",
  previous: null,
  next: null,
  current: null,
  year: null,
  currency:null,
  financial:null,
  adP_Id:null,
  isTrueDiag:""
 
};
export const DiagnoseReducer = persistReducer(
  
  { storage, key: "v711-diagnose" },
  (state = diagnosis, action) => {
    switch (action.type) {
      case ADD_TOLERENCE_RANGE:
        return {
          ...state,
          range: action.payload,
        };
      case SET_VSIGN: {
        console.log('new selectedVSign ====>>>> ', { ...state.selectedVSign, ...action.payload });
        return {
          ...state,
          selectedVSign: { ...state.selectedVSign, ...action.payload },
        };
      }
      case NEXT_DRAWER_STATE:
        return {
          ...state,
          nextDrawerState: action.payload
        };
      case ADD_DIAGNOSIS_DATA:
        return {
          ...state,
          vSignsDiagnosis: action.payload.vSignDiag,
          dM_Id: action.payload.id,
        };
        case GET_PRIMARYVIEW_DATA:
          return{
            ...state,
            currency:action.payload.data.currency,
            financial:action.payload.data.financialValuesIn,
            isTrueDiag:action.payload.data.diagnose
           
          }
         
      case UPDATE_DMID:
        return {
          ...state,
          dM_Id: action.payload,
        };
        case ADP_ID:
          return {
            ...state,
            adP_Id: action.payload,
          };
          
      case UPDATE_QUARTER:
        return {
          ...state,
          previous: action.payload.previous,
          next: action.payload.next,
          current: action.payload.current,
          year: action.payload.year,
        };
      case CLEAR:
        return diagnosis;
       
      default:
        return state;
    }
  }
);
