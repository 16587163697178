/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { Divider } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { getCVSbyCompany } from "../../../../../../app/config/company-profile-config/company-profile-service";
import { checkIsActive, toAbsoluteUrl } from "../../../../../_helpers";
import { useSelector } from "react-redux";
export function CTAsideMenuList({ layoutProps, vsigns }) {
  const location = useLocation();
  const [vsign, setVsign] = useState([]);
  const CMID = useSelector((state) => state.CompanyStepsReducer.cM_Id);
  const permVsign = useSelector((state) => state.PermissionReducer.vsigns);
  const { isInvited } = useSelector((state) => state.auth.user);
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  const selectedVsign = async () => {
    await getCVSbyCompany(CMID, setVsign, vsigns);
  };
  useEffect(() => {
    selectedVsign();
  }, []);
  return (
    <>
      {/* begin::Menu Nav */}
      {/* <ul
        className={`menu-nav data-auto-scroll="true" ${layoutProps.ulClasses}`}
      >
        {vsign &&
          vsign.length > 0 &&
          vsign.map((item) => (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                `/company-profile/create-template/${item.code.toLowerCase()}`,
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink
                className="menu-link menu-toggle"
                to={`/company-profile/create-template/${item.code.toLowerCase()}`}
              >
                <span className="svg-icon menu-icon">
                  <img
                    src={item.icon}
                    style={{ width: 25, height: 32, objectFit: "contain" }}
                  />
                </span>
                <span className="menu-text">{item.vS_Name}</span>
              </NavLink>
            </li>
          ))}
      </ul> */}
      {<ul
        className={`menu-nav data-auto-scroll="true" ${layoutProps.ulClasses}`}
      >
        {vsign &&
          vsign.length > 0 &&
          vsign.filter((i)=>i.vS_Id!=88).map((item) => (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                `/company-profile/create-template/${item.code.toLowerCase()}`,
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              {isInvited ?
                permVsign.length > 0 && permVsign.filter(val => val.vS_Id == item.vS_Id).map((val) =>
                (
                  <NavLink
                    className="menu-link menu-toggle"
                    to={`/company-profile/create-template/${item.code.toLowerCase()}`}
                  >
                    <span className="svg-icon menu-icon">
                      <img
                        src={item.icon}
                        style={{ width: 25, height: 25, objectFit: "contain" }}
                        alt=""
                      />
                    </span>
                    <span className="menu-text">{val.vS_Name}</span>
                  </NavLink>
                )
                )
                :
                <NavLink
                  className="menu-link menu-toggle"
                  to={`/company-profile/create-template/${item.code.toLowerCase()}`}
                >
                  <span className="svg-icon menu-icon">
                    <img
                      src={item.icon}
                      style={{ width: 25, height: 25, objectFit: "contain" }}
                      alt=""
                    />
                  </span>
                  <span className="menu-text">{item.vS_Name}</span>
                </NavLink>}
            </li>
          ))}
      </ul>}
      {/* <Divider />
      <ul
        className={`menu-nav data-auto-scroll="true" ${layoutProps.ulClasses}`}
      >
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "#",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="#">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Sidebar/whats-new.svg")}
              />
            </span>
            <span className="menu-text">What's New</span>
          </NavLink>
        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "#",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="#">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Sidebar/help.svg")} />
            </span>
            <span className="menu-text">Help</span>
          </NavLink>
        </li>
      </ul> */}

      {/* end::Menu Nav */}
    </>
  );
}
