import React, { createContext, useContext, useState } from "react";

const RoutesContext = createContext();

export function useRoutesContext() {
  return useContext(RoutesContext);
}

export const RoutesConsumer = RoutesContext.Consumer;

export function RoutesProvider({ children }) {
  const [page, setPage] = useState(null);
  const [helpAside, setHelpAside] = useState(null);
  const value = {
    page: page,
    setPage: setPage,
    helpAside,
    setHelpAside,
  };

  return (
    <RoutesContext.Provider value={value}>{children}</RoutesContext.Provider>
  );
}
