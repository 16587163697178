import React, { useMemo } from 'react';
import "./footer.styles.css";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import objectPath from "object-path";
import { toAbsoluteUrl } from "../../../_helpers";

export default function Footer(props) {

    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            disableScroll:
                objectPath.get(uiService.config, "aside.menu.dropdown") === "true" ||
                false,
            asideClassesFromConfig: uiService.getClasses("aside", true),
            disableAsideSelfDisplay:
                objectPath.get(uiService.config, "aside.self.display") === false,
            headerLogo: uiService.getLogo(),
        };
    }, [uiService]);

    const getHeaderLogo = () => {
        let result = "asquey-logo.png";
        if (layoutProps.headerSelfTheme && layoutProps.headerSelfTheme !== "dark") {
            result = "asquey-logo.png";
        }
        return toAbsoluteUrl(`/media/logos/${result}`);
    };

    return (
        <div class="Main-Footer">
            <ul>
                <li>
                    <img alt="logo" src={getHeaderLogo()} />
                </li>
                <li>
                    <a href="javascript:;">Privacy Policy</a>
                </li>
                <li>
                    <a href="javascript:;">Terms & Conditions</a>
                </li>
                <li>
                    <a href="javascript:;">Support</a>
                </li>
                <li>
                    <a href="javascript:;">Tutorials </a>
                </li>
                <li>
                    <a href="javascript:;">User Manual</a>
                </li>

            </ul>

        </div>
    )
}