import {
  AppBar,
  Avatar,
  Button,
  Divider,
  Hidden,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { HelpRounded, KeyboardArrowDown, Phone } from "@material-ui/icons";
import NotificationIcon from "@material-ui/icons/Notifications";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { actions as ReduxAction } from "../../../redux/auth-redux/authRedux";
import { ClearCompanyStepsDetail } from "../../../redux/companyStepsReducer";
import { ClearDiagnoseDetail } from "../../../redux/diagnoseReducer";
import { ClearDiagnoseResult } from "../../../redux/diagnoseResultsReducer";
import { helpSupport } from "../../../redux/companyStepsReducer";
import { clearPermission } from "../../../redux/permissionReducer";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useRoutesContext } from "../../routes/RoutesContext";
import PageHelpTipAside from "../../common/aside/PageHelpTipAside"
import { TopHeaderComponent } from './TopHeaderComponent';
import {SubHeader} from "../../../_metronic/layout/components/subheader/SubHeader"
import {Topbar} from '../../../_metronic/layout/components/header/Topbar'
import { assets } from "../../assets/index";
const useStyles = makeStyles((theme) => ({
  headerMain: {
    backgroundColor: "#fff",
    boxShadow: "none",
  },
  ButtonLabels: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  divider: {
    width: 1,
    height: 30,
    backgroundColor: "#203843",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  toolbar: {
    marginLeft: theme.spacing(11),
    marginRight: theme.spacing(10),
    backgroundColor: "#ffff",
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(6),
      marginRight: theme.spacing(5),
    },
    [theme.breakpoints.down("xs")]: {
      marginLuseDiseft: theme.spacing(2),
      marginRight: theme.spacing(2),
      justifyContent: "space-between",
    },
  },
  notificationButton: {
    textTransform: "none",
    color: "#203843",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  tilteBtn: {
    fontSize: 14,
    color: "black ",
    fontWeight: "bold",
  },
  link: {
    position: "relative",
    display: "block",
    textDecoration: "none",
    "&:hover,&:focus,&:visited,&": {
      color: "#303F9F",
    },
  },
  avatar: {
    width: 35,
    height: 35,
    objectFit: "contain",
    backgroundColor: "#203843",
  },
  headerImg: {
    width: "5%",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      width: "8%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "16%",
    },
  },
}));
export default function Header(props) {
  const RoutesContext = useRoutesContext();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false)
  const { user } = useSelector((state) => state.auth);
  console.log("user redux", user)
  const dispatch = useDispatch();
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };
  const history=useHistory()
  // const Logout = (e) => {
  //   e.preventDefault();
  //   handleMenuClose();
  //   localStorage.removeItem("");
  //   window.location.reload();
  //   //props.history.push('/')
  // };
  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const goToHome = () => {
    history.push("/welcome");
  };
  const menuId = "primary-search-account-menu";
  return (
    <>
 {/* { (RoutesContext.page == null)?   */}
 <AppBar position="static" className={classes.headerMain}> {/* Position changed to sticky from static for cosmetic issue #163 */}
      <Toolbar className={classes.toolbar}>
        <img
          src={assets.Logo}
          className={classes.headerImg}
          onClick={goToHome}
        />

        <Hidden xsDown>
          <Divider
            variant="fullWidth"
            orientation="vertical"
            className={classes.divider}
          />
          <Typography style={{ flexGrow: 1 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <a href="https://api.whatsapp.com/send/?phone=971505582431&amp;type=phone_number&amp;app_absent=0" target="_blank">
                    <img src={assets.Whatsapp} style={{ height: "35px", width: "35px" }}/>
                </a>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginLeft: "10px",
                  marginTop: '13px'
                }}
              >
                {/* <a>021-44421-123</a> */}
                <a href="https://api.whatsapp.com/send/?phone=971505582431&amp;type=phone_number&amp;app_absent=0" target="_blank" style={{color: "black"}}>+971 50 558 2431</a>
                <p>Help & Support</p>
              </div>
            </div>
          </Typography>
        </Hidden>
        <IconButton>
          {/* <HelpRounded style={{ color: "#203843" }}  /> */}
        </IconButton>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>Help and Support</Tooltip>}
        >
          <div
            className="topbar-item"
            onClick={() => {
            dispatch(
            helpSupport({
              status: true,
            }))
           setOpen(true)
            }}
          >
            <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2">
              <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
                <i class="fa fa-question-circle" aria-hidden="true"></i>
              </span>
            </div>
          </div>
        </OverlayTrigger>
        {/* <IconButton>
          <NotificationIcon style={{ color: "#203843" }} />
        </IconButton> */}
        <Button
          className={classes.notificationButton}
          aria-controls="customized-menu"
          aria-haspopup="true"
          onClick={handleProfileMenuOpen}
          startIcon={
            <Avatar alt="b" className={classes.avatar} sizes="5">
              {user.firstName.slice(0, 1).toUpperCase()}
            </Avatar>
          }
        // endIcon={<ExpandMoreIcon style={{ color: '#787879' }} />}
        >
          <div className={classes.ButtonLabels}>
            <span>
              {`${user.firstName} ${user.lastName}`}
              <KeyboardArrowDown style={{ color: "#203843" }} />
            </span>
            <span>{user.isMember ? "Member" : "Guest"}</span>
            <span>{user.persona}</span>
          </div>
        </Button>
        <Menu
          elevation={0}
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          id={menuId}
          keepMounted
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem
            onClick={() => {
              dispatch(ReduxAction.logout());
              dispatch(ClearDiagnoseDetail());
              dispatch(ClearCompanyStepsDetail());
              // dispatch(clearPermission());
              dispatch(ClearDiagnoseResult());
              history.push("/auth/login");
            }}
          >
            <Typography className={classes.tilteBtn}>Logout</Typography>
          </MenuItem>
          {(user.isMember) ?
            <MenuItem
              onClick={() => {

                history.push("/member-details");
              }}
            >
              <Typography className={classes.tilteBtn}>Edit Member Profile</Typography>
            </MenuItem> : (user.isInvited == false) ?
              <MenuItem
                onClick={() => {

                  history.push("/member-details");
                }}
              >
                <Typography className={classes.tilteBtn}>Become a Member</Typography>
              </MenuItem> : <></>}
        </Menu>
      </Toolbar>
      <div>
      { open?
          <PageHelpTipAside 
          setOpen={setOpen}/>:<></> }
      
      </div>
      <SubHeader/>
    </AppBar>
  </>
  );
}
